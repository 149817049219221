import DashboardSidebar from "./components/sidebar";
import NetworkManagementComponent from "./components/networkComponent";
import HeaderDashboard from "./components/header";
import Footer from "./components/footer";
export default function NetworkManagement(params) {
  return (
    <div className="page-top">
      <div id="wrapper">
        <div id="content-wrapper" class="d-flex flex-column">
        
          <NetworkManagementComponent />
          <Footer />
        </div>
      </div>
      
    </div>
  );
}
