import { useEffect, useState } from "react";
import {
  getAllCertificates,

  getAllRoles,
  showToastMessage,
} from "./functions/certificateManagementFuctions";
import { apiCall } from "../../../apicalls/apiCall";
import {
  createItemCertificate,
  deleteItemCertificate,
  getAllCompanyNameList,
  getAllRolesForCertificates,
  updateItemCertificate,
} from "../../../apicalls/apiRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
var ary = [];
export default function CertificateManagement() {
  const [userRoles, setUserRoles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [rendControl, setRendControl] = useState(false);
  const [renderingControl, setRenderingControl] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  var [postsPerPage, setPostPerPage] = useState(10);
  const [totalDataLenght, setTotalDataLenght] = useState(0);

  const [searchDto, setSearchDto] = useState({
    bookmark: "",
    limit: 10,
    page: currentPage,
    search: "",
  });
  const [dto, setDto] = useState({
    name: "",
    certificatePeer: "Packing House",
    visible: [],
    required: false,
    company : ""
  });
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    searchDto.page = selected + 1
    setRenderingControl(!renderingControl)
  };
  const [companyNameList, setCompanyName] = useState([])
  const [editData, setEditData] = useState({
    name: "",
    certificatePeer: "",
    visible: [],
    id: null,
    required: false
  });
  const [allCertificates, setAllCertificates] = useState([]);
  useEffect(() => {
    getAllUserRoles();
    getRoles()
  }, [rendControl]);

useEffect(()=>{
  getAllCompanyName()
},[dto?.certificatePeer])
  const [roles,setRoles] = useState([])
  const getRoles = () =>{
    apiCall('get' , getAllRolesForCertificates, {} , true).then((result) => {
      setRoles(result?.data?.data?.docs)
    }).catch((err) => {
      console.log("🚀 ~ apiCall ~ err:", err)
      toast('Internal Server Error. Please try again later')
    });
  }


  async function getAllUserRoles() {
    const response = await getAllRoles();
    response !== 0 && setUserRoles(response.data.data.docs);
  }
  useEffect(() => {
    getAllCertificatesData();
  }, [renderingControl]);

  const getAllCertificatesData = async () => {
    const allData = await getAllCertificates(searchDto);
    console.log(allData)
    setAllCertificates(allData.data.data.docs);
setTotalDataLenght(allData?.data?.totalAssets)
  };

  const valueAdder = (e) => {
    e.preventDefault();
    setDto((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const editValueAdder = (e) => {
    e.preventDefault();
    console.log(e.target.id, e.target.value);
    e.target.id == "visible"
      ? editData.visible.push(e.target.value)
      : setEditData((prev) => {
          return { ...prev, [e.target.id]: e.target.value };
        });
        if(e.target.id === "certificatePeer"){
          dto.certificatePeer = e.target.value
        }
  };

  const formHandler = (e) => {
    e.preventDefault();

    if (dto.visible.length === 0) {
      showToastMessage("Please select some visible ", "red", 1);
      return 0;
    } else {
      setIsLoading(true);
      apiCall("post", createItemCertificate, dto, true)
        .then((res) => {
          showToastMessage("A certificate added successfully", "green", 1);
          setRenderingControl(!renderingControl);
          document.querySelector("#closeModalButtonAddNew").click();
          e.target.reset();
          setIsLoading(false);
        })
        .catch((Err) => {
          console.log(Err, "post");
          setIsLoading(false);
          showToastMessage(Err.response.data.message, "red", 0);
        });
    }
  };

  const editFormHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setEditData({
      visible: ary,
    });
    apiCall("post", updateItemCertificate, editData, true)
      .then((res) => {
        console.log(res, "response");
        setRenderingControl(!renderingControl);
        showToastMessage("An entry Editted Successfully", "green", 1);
        document.querySelector("#closeModalButtonEdit").click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: certificateManagement.js:69 ~ apiCall ~ err:",
          err
        );
        showToastMessage(err.response.data.message, "red", 0);

        setIsLoading(false);
      });
  };

  const deleteACertificate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    apiCall("post", deleteItemCertificate, { id: editData?.id }, true)
      .then((res) => {
        console.log(res);
        document.querySelector("#closeModalButtonDelete").click();
        showToastMessage("An item deleted Successfully", "green", 1);
        setRenderingControl(!renderingControl);
        setIsLoading(false);
      })

      .catch((err) => {
        console.log(
          "🚀 ~ file: certificateManagement.js:63 ~ apiCall ~ err:",
          err
        );
        setIsLoading(false);
        showToastMessage(err.response.data.message, "red", 0);
      });
  };
  var expanded = false;

  function showCheckboxes(check) {
    console.log("here i am");

    var checkboxes = document.getElementById(
      check ? "checkboxes1" : "checkboxes"
    );
    console.log(checkboxes);
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  function getAllCompanyName () {
    const url =dto?.certificatePeer=='Packing House'?  getAllCompanyNameList + "/" + 'packing' :  getAllCompanyNameList + "/" +  dto?.certificatePeer 
        console.log("🚀 ~ getAllCompanyName ~ url:", url)
    apiCall('post',url, {} , false).then((result) => {
      console.log("🚀 ~ apiCall ~ result:", result)
      setCompanyName(result?.data?.data?.docs)
    }).catch((err) => {
        console.log("🚀 ~ apiCall ~ err:", err)
        toast.warn('Internal Server Error. Please try again later')
    });
  }
  
var bry = []
  function checkboxFunctionality(e, check) {
    console.log(e.target.checked)
    if(check){
      if (e.target.checked) {
        ary.indexOf(e.target.value) == -1 &&  ary.push(e.target.id);
      } else {
        ary.splice(ary.indexOf(e.target.id), 1);
      }
      editData.visible =ary 
    }
    else{
       if (e.target.checked) {
      bry.indexOf(e.target.value) == -1 &&  bry.push(e.target.id);
    } else {
      bry.splice(bry.indexOf(e.target.id), 1);
    }
   
    dto.visible = bry 

    }
  
  }

  return (
    <>
      <div>
        <div id="d-flex flex-column">
          <div>
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <h1
                      className="h3 mb-0 text-gray-800 first-heading"
                      id="usermanagement-h1"
                    >
                      Certificate Management
                    </h1>
                  </div>
                  <div className="col-sm">
                    <div className="search d-flex butonn">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by certificate peer"
                        onChange={(e) => {
                          searchDto.search = e.target.value;
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          searchDto.page = 1;
                          setRenderingControl(!renderingControl);
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-2" style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalCenter"
                    >
                      <i className="fa fa-plus mr-2"></i>Add
                    </button>
                  </div>
                </div>

                {/* Add new item modal */}

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Add New
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonAddNew"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  id="form1"
                                  onSubmit={formHandler}
                                >
                                  <div className="form-group row">
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="name">Name</label>
                                      <input
                                        type="text"
                                        id="name"
                                        onChange={valueAdder}
                                        className="form-control form-control-user"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3 ">
                                      <label htmlFor="certificatePeer">
                                        Certificate of Peer
                                      </label>
                                      <select
                                        id="certificatePeer"
                                        className="form-select"
                                        onChange={valueAdder}
                                      >
                                      {roles?.map((role)=>{
                                        return(<option value={role?.name}>{role.name}</option>)
                                      })}
                                      </select>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Visible
                                      </label>
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-light w-100 text-start "
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          style={{
                                            backgroundColor: "transparent",
                                            borderColor: "#D1D3E2",
                                            color: "#6D767E",
                                          }}
                                        >
                                          Visible
                                        </button>
                                        <ul
                                          className="dropdown-menu w-100 text-end p-3 "
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {userRoles.map((element, index) => {
                                            return (
                                              <div className="d-flex w-100 m-auto">
                                                <li
                                                  className=""
                                                  style={{
                                                    fontSize: "1rem",
                                                    textAlign: "left",
                                                    color: "#6D767E",
                                                    width: "70%",
                                                  }}
                                                  key={index}
                                                  id={element?._id}
                                                >
                                                  {element?.name}
                                                </li>
                                                <div
                                                  style={{ width: "20%" }}
                                                  className="mt-1"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={element?._id}
                                                    onClick={(e)=>{
                                                      checkboxFunctionality(e, false)
                                                    }
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Company
                                      </label>
                                      <select   className="form-select" onChange={(e)=>{
                                        setDto((prev)=>{
                                          return{...prev, ['company'] : e.target.value}
                                        })
                                      }}>
                                        <option selected disabled>--Select Company Name --</option>
                                        {companyNameList?.map((ele)=>{
                                          return(

                                         
                                          <option value={ele?.company}> {ele?.company}</option>
                                          )
                                        })}
                                      </select>
                                      </div>
                                    <div className="col-sm-6 mb-3">
                                     <label htmlFor="requiredCheck"> Is this required ? <br /> <input className="form-field mt-3" id="requiredCheck" type="checkbox" onChange={(e)=>{
                                      dto.required = e.target.checked ? true : false
                                     }}></input></label>
                                      </div>
                                    <div className="col-sm-6 mb-3 text-end mt-4">
                                     
                                      <button type="submit" className="btn btn-primary  d-flex flex-wrap">
                                        Submit
                                        <i
                                          style={
                                            isLoading
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                          className="fa fa-spinner fa-spin m-1"
                                        ></i>
                                      </button>

                                      {/* <button
                                        type="submit"
                                        className="btn btn-primary d-flex flex-row"
                                      >
                                        Submit
                                        <i
                                          style={
                                            isLoading
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                          className="fa fa-spinner fa-spin m-1 ms-2"
                                        ></i>
                                      </button> */}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* edit Modal */}

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="editModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Edit Listing
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonEdit"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  id="form1"
                                  onSubmit={editFormHandler}
                                >
                                  <div className="form-group row">
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="name">Name</label>
                                      <input
                                        type="text"
                                        id="name"
                                        value={editData.name}
                                        onChange={editValueAdder}
                                        className="form-control form-control-user"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3 ">
                                      <label htmlFor="certificatePeer">
                                        Certificate of Peer
                                      </label>
                                      <select
                                        id="certificatePeer"
                                        className="form-select"
                                        onChange={editValueAdder}
                                        value={editData?.certificatePeer}
                                      >
                                      {roles?.map((role)=>{
                                        return(<option value={role?.name}>{role.name}</option>)
                                      })}
                                      </select>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Visible
                                      </label>
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-light w-100 text-start "
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          style={{
                                            backgroundColor: "transparent",
                                            borderColor: "#D1D3E2",
                                            color: "#6D767E",
                                          }}
                                        >
                                          Visible
                                        </button>
                                        <ul
                                          className="dropdown-menu w-100 text-end p-3 "
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {userRoles.map((element, index) => {
                                            
                                            return (
                                              <div className="d-flex w-100 m-auto">
                                                <li
                                                  className=""
                                                  style={{
                                                    fontSize: "1rem",
                                                    textAlign: "left",
                                                    color: "#6D767E",
                                                    width: "70%",
                                                  }}
                                                  key={index}
                                                  id={element?._id}
                                                >
                                                  {element?.name}
                                                </li>
                                                <div
                                                  style={{ width: "20%" }}
                                                  className="mt-1"
                                                >
                                                  {
                                                    ary.indexOf(element?._id) !== -1 ? 
                                                    <>
                                                     <input
                                                    type="checkbox"
                                                    id={element?._id}
                                                   
                                                    onClick={(e)=>{
                                                      checkboxFunctionality(e,true)
                                                    }
                                                    }
                                                    checked
                                                  />
                                                    </>  : <>
                                                    <input
                                                    type="checkbox"
                                                    id={element?._id}
                                                    
                                                    onClick={(e)=>{
                                                      checkboxFunctionality(e,true)
                                                    }
                                                    }
                                                  />
                                                    </>
                                                  }
                                                  {/* <input
                                                    type="checkbox"
                                                    id={element?._id}
                                                    checked={editData?.visible?.indexOf(element?._id) !== -1 && true}
                                                    onClick={(e)=>{
                                                      checkboxFunctionality(e,true)
                                                    }
                                                    }
                                                  /> */}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Company
                                      </label>
                                      <select value={editData?.company} className="form-select" onChange={(e)=>{
                                        setEditData((prev)=>{
                                          return{...prev, ['company'] : e.target.value}
                                        })
                                      }}>
                                    {
                                      companyNameList?.map((ele)=>{
                                        return(
                                          <option value={ele?.company}>{ele?.company}</option>
                                        )
                                      })
                                    }
                                      </select>
                                      </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="editRequired">
                                          Is this required ?<br />
                                          <input id="editRequired" className="mt-3"  checked={editData.required} type="checkbox" onChange={(e)=>{
                                           
                                            setEditData({ ...editData, required: e.target.checked });
                                          }
                                          }/>
                                        </label>
                                        </div>
                                    <div className="col-sm-6 mb-3 text-end mt-4">
                                   
                                      <button type="submit" className="btn btn-primary  d-flex flex-wrap">
                                      Update<i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                            </button>

                                      {/* <button
                                        type="submit"
                                        className="btn btn-primary d-flex flex-row"
                                      >
                                        Update
                                        <i
                                          style={
                                            isLoading
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                          className="fa fa-spinner fa-spin m-1 ms-2"
                                        ></i>
                                      </button> */}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* delete modal  */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="deleteModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Delete entry
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonDelete"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        
                          <div className="row">
                            <div className="col-12">
                              <div className="p-0  ">
                                <div className="modal-body">
                                Are you sure ?
                                </div>
                                
                                <div className="modal-footer mb-0">
                                <button
                                  className="btn btn-secondary m-3 ms-2"
                                  onClick={() => {
                                    document
                                      .querySelector("#closeModalButtonDelete")
                                      .click();
                                  }}
                                >
                                  Cancel
                                </button>
                               

                         

                                {/* <button
                                  className="btn btn-primary m-3 mb-0 w65 d-flex flex-row "
                                  onClick={deleteACertificate}
                                >
                                  Yes
                                  <i
                                    style={
                                      isLoading
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                    className="fa fa-spinner fa-spin m-1"
                                  ></i>
                                </button> */}
                                <button type="submit" onClick={deleteACertificate} className="btn btn-primary  m-3 d-flex flex-row">
                              Delete  <i style={isLoading? {display:"block"} : {display: 'none'}} className="fa fa-spinner fa-spin m-1"></i>
                            
                            </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="table-responsive custom-table">
                      <table
                        class="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Certificate peers</th>
                            <th>Required</th>
                            <th>Name</th>
                            <th>Company</th>
                            <th>visible</th>
                            <th class="nowrap">Edit Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allCertificates.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{ele?.certificatePeer}</td>
                                <td>{ele?.required ? 'true' : 'false'}</td>
                                <td>{ele?.name}</td>
                                <td>{ele?.company}</td>
                                <td>
                                  {ele?.visible?.map((element, index) => {
                                    return (
                                      <span key={index}>
                                        {element?.name} |{" "}
                                      </span>
                                    );
                                  })}
                                </td>
                                <td
                                  onClick={() => {
                                    var objct = [];
                                    ele?.visible?.map((obj) => {
                                      objct.push(obj._id);
                                    });
                                    const letters = new Set(objct);
                                    ary = []
                                    ary = Array.from(letters);
                                   
                                    setEditData({
                                      name: ele?.name,
                                      certificatePeer: ele?.certificatePeer,
                                      visible: ary,
                                      id: ele?.id,
                                      required : ele?.required,
                                      company : ele?.company
                                    });
                            
                                  }}
                                >
                                  <i
                                    className="fa fa-edit"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editModal"

                                  />
                                  <i
                                    className="fa fa-trash-o m-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <ReactPaginate
                            onPageChange={paginate}
                            pageCount={Math.ceil(
                              totalDataLenght / postsPerPage
                            )}
                            previousLabel={"<"}
                            nextLabel={">"}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-number"}
                            previousLinkClassName={"page-number"}
                            nextLinkClassName={"page-number"}
                            activeLinkClassName={"active"}
                          />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
