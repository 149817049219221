import "../css/sb-admin-2.min.css";
import headerImg from "../img/logoforFoodChain.png";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../apicalls/apiRoutes";
import { apiCall } from "../apicalls/apiCall";
import { useEffect, useState } from "react";
import Timer from "./component Main/timer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassword(params) {

  const showToastMessage = (text,color,notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
    else{
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
   
  }

  const navigate = useNavigate();
  const [rendControl, setRendControl] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setConfirmationText("");
    }, 3000);
  }, [rendControl]);

  const [emailAddress, setEmailAddress] = useState({
    email: ""
  });
  const emailToSend = emailAddress.email
  const [confirmationText, setConfirmationText] = useState("");
  const handleNavigation = () => {
    const url = "/resetPassword/?email=" + emailAddress.email
setTimeout(() => {
  navigate(url)
}, 3000);

  };
  const submitHandler = (e) => {
    e.preventDefault();
    apiCall("post", forgotPassword, emailAddress)
      .then((result) => {
        if (result.status == 200) {
          showToastMessage('Forgot password Link have been sent to your email' , 'green' , 1)
          handleNavigation();
        }
      })
      .catch((err) => {
        
       showToastMessage(err?.response?.data?.message, 'red' , 2) 
      });
  };
  return (
    <div className="login-background" style={{ height: "100vh" }}>
      <ToastContainer 
       position="bottom-center"
       autoClose={2000}
       hideProgressBar={true}
       />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="p-5">
                    <div className="text-center">
                      <img src={headerImg} style={{width : '60%' , height : '60%'}} className="mb-5 mt-3" />
                      <h1 className="h4 text-gray-900 mb-2">
                        Forgot Your Password?
                      </h1>
                      <p className="mb-4">
                        We get it, stuff happens. Just enter your email address
                        below and we'll send you a link to reset your password!
                      </p>
                    </div>
                    <form className="user" onSubmit={submitHandler}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-user"
                          id="email"
                          onChange={(e) => {
                            setEmailAddress({ email: e.target.value });
                          }}
                          aria-describedby="emailHelp"
                          placeholder="Email Address"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                      >
                        Reset Password
                      </button>
                    </form>
                    <p className="mt-4" style={{ textAlign: "center" }}>
                      {confirmationText}
                    </p>
                    <div className="justify-content-center d-flex">
                      <div className="creat-account mr-3"></div>
                      <div className="have-login">
                      
                        <a
                          className="small"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Already have an account? Login!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
