import { useEffect, useState } from "react";
import { apiCall } from "../../../apicalls/apiCall";
import {
  getAllItemLoadAtPacking,
  updateItemLoadAtPacking,
  deleteItemLoadAtPacking,
  loadItemLoadAtPacking,
  getAllRecievingCode,
  getAllSuppliers,
  queryItemPacking,
  getAllCompanyNameList,
} from "../../../apicalls/apiRoutes";
import "../../../css/sb-admin-2.min.css";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableLoader } from "../../component Main/loader";
import { timeConversion } from "../../component Main/timeConversion";
function LoadingAtPackingHouse(params) {
  const [currentPage, setCurrentPage] = useState(1);
  var [postsPerPage, setPostPerPage] = useState(10);
  const [totalDataLenght, setTotalDataLenght] = useState();
  const [currentRecords, setCurrentRecord] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [idArray, setIdArray] = useState([]);
  const [rendControl, setrendControl] = useState(false);
  const [supplierName, setSupplierName] = useState();
  const [dataForApiCall, setDataForApiCall] = useState({
    bookmark: "",
    limit: postsPerPage,
    page: currentPage,
    search: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  const [IdToDelete, setIdToDelete] = useState({
    id: null,
  });
  const [Data, setData] = useState();
  const [addNewEntryData, setAddNewEntryData] = useState({
    receiveCode: "",
    postHarvestTreatmentDate: "",
    postHarvestMethod: "",
    packaging: "",
    unit: null,
    quantity: null,
    client: "",
    materialId: "",
  });

  const [editEntryData, setEditEntryData] = useState({
    id: null,
    receiveCode: "",
    postHarvestTreatmentDate: "",
    postHarvestMethod: "",
    packaging: "",
    unit: null,
    quantity: null,
    client: "",
    materialId: "",
  });

  const getAllLoadingData = () => {
    setIsFetching(true);
    apiCall("post", getAllItemLoadAtPacking, dataForApiCall, true)
      .then((result) => {
        setTotalDataLenght(result?.data?.data?.docs?.length);
        setData(result.data);
        setIsFetching(false);
      })
      .catch((err) => {
        setData(err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const [allCompany, setAllCompany] = useState([]);
  const getAllCompanyName = () => {
    apiCall(
      "post",
      getAllCompanyNameList + "/Manufacturer%20Warehouse",
      {},
      false
    )
      .then((result) => {
        setAllCompany(result?.data?.data?.docs);
      })
      .catch((err) => {
        console.log("🚀 ~ apiCall ~ err:", err);
        toast("Internal server error. Please reload the page");
      });
  };

  const getAllSupplierName = () => {
    apiCall("get", getAllSuppliers, {}, true)
      .then((result) => {
        setSupplierName(result?.data?.data?.docs);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: recievingAtPackingHouse.js:244 ~ apiCall ~ err:",
          err
        );
        toast.warn(err.response.data.message);
      });
  };
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const storeValues = (e) => {
    setAddNewEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const storeIdsInObj = (e) => {
    const newObj = JSON.parse(e);
    setAddNewEntryData((prev) => {
      return {
        ...prev,
        ["receiveCode"]: newObj.receivingMaterialCode,
        ["materialId"]: newObj._id,
      };
    });
  };

  const addNewEntry = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(addNewEntryData.postHarvestMethod)) {
      showToastMessage(
        "Post harvest method Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(addNewEntryData.packaging)) {
      showToastMessage(
        "Packaging Material Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    }
    setIsLoading(true);
    setIsFetching(true);
    apiCall("post", loadItemLoadAtPacking, addNewEntryData, true)
      .then((res) => {
        showToastMessage("Loaded at packing Uploaded Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonAddNew").click();
        setIsFetching(false);
        const element = document.getElementById("form1");
        element?.reset();
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const editFunctionality = (e) => {
    setEditEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const selectValues = (ele) => {
    console.log(ele, ele?.receiving_material?._id);
    const convertedDate = timeConversion(ele?.dateofPostHarvestTreatment);
    getDateThreshold(ele?.receiving_material?._id);
    setEditEntryData({
      id: ele?.id,
      client: ele?.supplytoClients,
      packaging: ele?.packagingMaterials,
      postHarvestMethod: ele?.postHarvestProcessingMethod,
      postHarvestTreatmentDate: convertedDate,
      quantity: ele?.quantityofLoading,
      receiveCode: ele?.receiving_material?.receivingMaterialCode,
      unit: ele?.unitPerBag,
    });
  };
  const EditEntrySubmit = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(editEntryData.postHarvestMethod)) {
      showToastMessage(
        "Post harvest method Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(editEntryData.packaging)) {
      showToastMessage(
        "Packaging Material Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    }
    setIsLoading(true);
    setIsFetching(true);
    apiCall("post", updateItemLoadAtPacking, editEntryData, true)
      .then((res) => {
        showToastMessage("Loading at packing editted Successfully", "green", 1);
        setrendControl(!rendControl);
        e.target.reset();
        setIsLoading(false);
        document.getElementById("closeModalButtonEdit").click();
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  const deleteFunctionality = (e) => {
    setIsLoading(true);
    setIsFetching(true);
    apiCall("post", deleteItemLoadAtPacking, IdToDelete, true)
      .then((res) => {
        showToastMessage("Loaded at packing removed Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonDelete").click();
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  useEffect(() => {
    getAllLoadingData();
  }, [currentPage, rendControl]);

  const [dateThreshold, setThresholdDate] = useState();
  const getDateThreshold = (id) => {
    apiCall("post", queryItemPacking, { id: id }, true)
      .then((result) => {
        console.log(
          "🚀 ~ file: LoadingAtPackingHouse.js:219 ~ apiCall ~ result:",
          result
        );
        const dataa = new Date(result?.data?.data?.dateofReceiving)
          ?.toISOString()
          .split("T")[0];
        console.log(
          "🚀 ~ file: LoadingAtPackingHouse.js:220 ~ apiCall ~ dataa:",
          dataa
        );
        setThresholdDate(dataa);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: LoadingAtPackingHouse.js:219 ~ apiCall ~ err:",
          err
        );
      });
  };

  useEffect(() => {
    addNewEntryData?.materialId !== "" &&
      getDateThreshold(addNewEntryData?.materialId);
  }, [addNewEntryData?.materialId]);
  const searchTable = (e) => {
    var input, filter, table, tr, td, i, txtValue;
    input = e.target.value;
    filter = input.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  useEffect(() => {
    getAllCompanyName();
    getAllSupplierName();
    apiCall("get", getAllRecievingCode, {}, true)
      .then((res) => {
        setIdArray(res.data.data.docs);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: LoadingAtPackingHouse.js:153 ~ useEffect ~ err:",
          err
        );
      });
  }, []);

  const checkURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <>
      <div>
        <div id="d-flex flex-column">
          <div>
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <h1
                      className="h3 mb-0 text-gray-800 first-heading"
                      id="usermanagement-h1"
                    >
                      Operations at Packing House
                    </h1>
                  </div>
                  <div class="col-sm">
                    <div className="search d-flex butonn">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search recieving code"
                        onChange={(e) => {
                          setDataForApiCall((prev) => {
                            return { ...prev, ["search"]: e.target.value };
                          });
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setCurrentPage(1);
                          setrendControl(!rendControl);
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-2" style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalCenter"
                    >
                      <i className="fa fa-plus mr-2"></i>Add
                    </button>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Add New
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonAddNew"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={addNewEntry}
                                  id="form1"
                                >
                                  <div className="form-group row">
                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving material code
                                      </label>
                                      <select
                                        className="form-select"
                                        onChange={(e) => {
                                          storeIdsInObj(e.target.value);
                                        }}
                                      >
                                        <option disabled selected>
                                          --- Select A Receiving code ---
                                        </option>
                                        {idArray.map((ele) => {
                                          return (
                                            <option value={JSON.stringify(ele)}>
                                              {ele.receivingMaterialCode}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-12 mb-3 ">
                                      <label htmlFor="birthday">
                                        Date of Post-Harvest Treatment
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="postHarvestTreatmentDate"
                                        min={dateThreshold}
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Post-Harvest Treatment/Processing Method
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="postHarvestMethod"
                                        placeholder="Harvest"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Packaging Materials
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="packaging"
                                        placeholder="Packaging"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Unit Per Bag (kgs)
                                      </label>
                                      <input
                                        type="number"
                                        min="1"
                                        className="form-control form-control-user"
                                        id="unit"
                                        placeholder="Unit"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    
                                    <div className="col-sm-6">
                                      <label htmlFor="exampleInputEmail1">
                                        Quantity of Loading
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="quantity"
                                        min="1"
                                        placeholder="Quantity (KGs)"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Supply to Manufacturing warehouse
                                      </label>
                                      <select
                                        className="form-control form-control-user"
                                        id="client"
                                        placeholder="Supplier Name"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      >
                                        <option disabled selected>
                                          --Select a supplier--
                                        </option>
                                        {allCompany?.map((supplier) => {
                                          return (
                                            <option
                                              value={supplier?.company}
                                            >
                                              {supplier?.company}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary float-end   d-flex flex-wrap"
                                    type="submit"
                                    style={{ display: "flex" }}
                                  >
                                    Submit
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="editModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Edit Listing
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonEdit"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={EditEntrySubmit}
                                >
                                  <div className="form-group row">
                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving material code
                                      </label>
                                      <input
                                        disabled
                                        type="text"
                                        className="form-control form-control-user"
                                        id="receiveCode"
                                        value={editEntryData?.receiveCode}
                                        placeholder="Code"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-12 mb-3 ">
                                      <label htmlFor="birthday">
                                        Date of Post-Harvest Treatment
                                      </label>
                                      <input
                                        type="date"
                                        min={dateThreshold}
                                        value={
                                          editEntryData?.postHarvestTreatmentDate
                                        }
                                        className="form-control form-control-user"
                                        id="postHarvestTreatmentDate"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>

                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Post-Harvest Treatment/Processing Method
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="postHarvestMethod"
                                        value={editEntryData.postHarvestMethod}
                                        placeholder="Harvest"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Packaging Materialss
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="packaging"
                                        value={editEntryData.packaging}
                                        placeholder="Packaging"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Unit Per Bag (kgs)
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="unit"
                                        value={editEntryData.unit}
                                        placeholder="Unit"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>

                                    
                                    <div className="col-sm-6">
                                      <label htmlFor="exampleInputEmail1">
                                        Quantity of Loading(kg)
                                      </label>
                                      <input
                                        type="number"
                                        min="1"
                                        className="form-control form-control-user"
                                        id="quantity"
                                        value={editEntryData.quantity}
                                        placeholder="Quantity"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Supply to Manufacturing warehouse
                                      </label>

                                      <select
                                        className="form-control form-control-user"
                                        id="client"
                                        placeholder="Supplier Name"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      >
                                        <option disabled selected>
                                          --Select a supplier--
                                        </option>
                                        {allCompany?.map((supplier) => {
                                          return (
                                            <option
                                              value={supplier?.company}
                                            >
                                              {supplier?.company}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary float-end   d-flex flex-wrap"
                                    type="submit"
                                    style={{ display: "flex" }}
                                  >
                                    Update
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary"></h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive custom-table">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered fold-table"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0"
                          >
                            <thead>
                              <tr>
                                <th>Receiving material code</th>
                                <th>Date of Post-Harvest Treament</th>
                                <th>Post-Harvest Treament/Processing Method</th>
                                <th>Packaging Materialss</th>
                                <th>Unit per Bag (Kgs)</th>
                                <th>Quantity of Loading (Kgs)</th>

                                <th>Supply to Manufacturing warehouse</th>
                                <th>Loading Material Code</th>
                                <th className="nowrap">Edit Actions</th>
                              </tr>
                            </thead>
                            {isFetching ? (
                              <tr>
                                <td colSpan="10">
                                  {" "}
                                  <TableLoader />
                                </td>
                              </tr>
                            ) : Data?.data?.docs?.length ? (
                              <tbody>
                                {Data?.data?.docs.map((ele, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {
                                          ele.receiving_material
                                            .receivingMaterialCode
                                        }
                                      </td>
                                      <td>{ele.dateofPostHarvestTreatment}</td>
                                      <td>
                                        {ele.postHarvestProcessingMethod}{" "}
                                      </td>
                                      <td>{ele.packagingMaterials}</td>
                                      <td>{ele.unitPerBag}</td>
                                      <td>{ele.quantityofLoading}</td>

                                      <td>{ele.supplytoClients}</td>
                                      <td>{ele.loadingMaterialCode}</td>
                                      <td>
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#editModalCenter"
                                        >
                                          <i
                                            className="fa fa-edit"
                                            id={ele._id}
                                            onClick={() => {
                                              selectValues(ele);
                                            }}
                                          />
                                        </a>
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                        >
                                          <i
                                            className="fa fa-trash-o m-1"
                                            id={ele.id}
                                            onClick={(e) => {
                                              setIdToDelete({
                                                id: e.target.id,
                                              });
                                            }}
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tr>
                                <td colSpan="10">No Record Found</td>
                              </tr>
                            )}
                          </table>
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={Math.ceil(
                              totalDataLenght / postsPerPage
                            )}
                            previousLabel={"<"}
                            nextLabel={">"}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-number"}
                            previousLinkClassName={"page-number"}
                            nextLinkClassName={"page-number"}
                            activeLinkClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete an Entry
                </h5>
                <button
                  className="close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure ?</div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  Cancel
                </button>
                <a
                  className="btn btn-primary"
                  style={{ display: "flex" }}
                  onClick={deleteFunctionality}
                >
                  Delete{" "}
                  <i
                    style={
                      isLoading ? { display: "block" } : { display: "none" }
                    }
                    className="fa fa-spinner fa-spin m-1"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoadingAtPackingHouse;
