import "../css/sb-admin-2.min.css";
import { useNavigate } from "react-router-dom";
import headerImg from "../img/logoforFoodChain.png";
import { useEffect, useState } from "react";
import { adminLogin } from "../apicalls/apiRoutes";
import { apiCall } from "../apicalls/apiCall";
import { stringify } from "uuid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Homepage(params) {

  const showToastMessage = (text,color,notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
    else{
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
   
  }
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("tokenFoodPro");
    if (token) {
      navigate("/dashboard/Product-traceability");
    }
  })

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [errRendControl, setErrRendControl] = useState(false);
  const [ConfirmationText, setConfimationText] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    apiCall("post", adminLogin, data)
      .then((result) => {
        if (result.status == 200) {
          const token = result?.data?.data?.token;
          localStorage.setItem("tokenFoodPro", token);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(result?.data?.data?.user)

          )
        
          localStorage.setItem("userRole" ,
            result?.data?.data?.role)
            if ((result?.data?.data?.user?.role?._id) == 0){
              navigate("/dashboard/usermanagement")
            }
            else{
              navigate("/dashboard/Product-traceability")
            }
          
        } else {
          showToastMessage('Username Or password is incorrect', 'red' ,2)
        }
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message , 'red' , 2)
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setConfimationText("");
    }, 3000);
  }, [errRendControl]);

  const fieldAdder = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

const passwordFieldHandler = (e) =>{
const element = document.getElementById(e.target.id)
const passField = document.getElementById('password')
if (passField.type == "password"){
  element.setAttribute('class' , "fa fa-eye-slash")
  passField.type= "text"
}
else{
  passField.type ="password"
  element.setAttribute('class' , "fa fa-eye")
}
}

  return (
    <div className="login-background">
      <ToastContainer 
       position="bottom-center"
       autoClose={2000}
       hideProgressBar={true}
       />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="p-4 bg-gradient-primary login2">
                  <div className="text-center">
                    <img src={headerImg} style={{width:'60%' , height:'60%' }} className="mb-5 mt-5" />
                    <h1 className="h4  mb-4"></h1>
                  </div>
                  <form className="user" onSubmit={submitHandler}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-user"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Email Address"
                        onChange={fieldAdder}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-user"
                        id="password"
                        placeholder="Password"
                        onChange={fieldAdder}
                        required

                        
                      />
                      <span className="p-viewer" >
        <i className="fa fa-eye" aria-hidden="true" id="iconForPassword" onClick={passwordFieldHandler}></i>
    </span>        
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-user btn-block mb-3"
                    >
                      Login
                    </button>
                    <p
                      style={{
                        color: "white",
                        fontSize: "1vw",
                        textAlign: "center",
                      }}
                    >
                    </p>
                  </form>
                  <div className="justify-content-center d-flex border-top">
                    <div className=" forgot-password mt-3">
                      <a
                        className="small"
                        onClick={() => {
                          navigate("/forgotpassword");
                        }}
                      >
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Homepage;