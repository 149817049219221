import "../../../css/sb-admin-2.min.css";
import headerImg from "../../../img/logoforFoodChain.png";
import { library, icon } from "@fortawesome/fontawesome-svg-core";
import {
  faAnchor,
  faArrowAltCircleLeft,
  faArrowRight,
  faFontAwesome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faTable } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function DashboardSidebar(params) {
  const userInfo = localStorage.getItem("userDetails");
  const userrole = JSON.parse(userInfo);

  const currentURL = window.location.href;
  const chunkUrl = currentURL.split("/");
  const lastUrl = chunkUrl[chunkUrl.length - 1];
  // Get all the navigation links in the sidebar
  const navigationLinks = document.querySelectorAll(".nav-link");

  // Add a click event listener to each navigation link
  navigationLinks.forEach((link) => {
    link.addEventListener("click", () => {
      // Remove the "active" className from all navigation links
      navigationLinks.forEach((link) => {
        link.classList.remove("active");
      });

      // Add the "active" className to the clicked navigation link
      link.classList.add("active");
    });
  });

  // Check the current URL and apply the "active" className to the corresponding navigation link
  const currentPath = window.location.pathname;

  navigationLinks.forEach((link) => {
    const href = link.getAttribute("href");
    if (currentPath === href) {
      link.classList.add("active");
    }
  });
  
  const navigate = useNavigate();
  return (
    <div>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion position-fixed zindex "
        id="accordionSidebar"
      >
        <a
          className="sidebar-brand  align-items-center "
          style={{ height: "auto" }}
        >
          <div className="sidebar-brand-icon rotate-n-15"></div>
          <div className="logo" style={{ width: "100%", margin: "auto" }}>
            <img className="img-profile" src={headerImg} width="90%" />
            <sup></sup>
          </div>
        </a>
        {userrole?.role?._id == 0 && (
          <>
            <div className="sidebar-divider my-0">
              <li
                className={`nav-item`}
              >
                <a
                  className="nav-link"
               href="http://13.214.243.244:3000/"
               target="_blank"
                >
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span className="ml-2">Hyperledger Dashboard</span>
                </a>
              </li>
            </div>
            <div className="sidebar-divider my-0">
              <li
                className={`nav-item ${
                  lastUrl === "usermanagement" ? "active" : ""
                }`}
              >
                <a
                  className="nav-link"
                  onClick={() => {
                    navigate("usermanagement");
                  }}
                >
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span className="ml-2">User Management</span>
                </a>
              </li>
            </div>
            <div className="sidebar-divider my-0">
              <li
                className={`nav-item ${
                  lastUrl === "networkmanagement" ? "active" : ""
                }`}
              >
                <a
                  className="nav-link"
                  onClick={() => {
                    navigate("networkmanagement");
                  }}
                >
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span className="ml-2">Network Management</span>
                </a>
              </li>
            </div>
            <div className="sidebar-divider my-0">
              <li
                className={`nav-item ${
                  lastUrl === "certificatemanagement" ? "active" : ""
                }`}
              >
                <a
                  className="nav-link"
                  onClick={() => {
                    navigate("certificatemanagement");
                  }}
                >
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span className="ml-2">Certificate Management</span>
                </a>
              </li>
            </div>
          </>
        )}
        <div className="sidebar-divider">
          <li
            className={`nav-item ${
              lastUrl === "Product-traceability" ? "active" : ""
            }`}
          >
            <a
              className="nav-link"
              onClick={() => {
                navigate("Product-traceability");
              }}
            >
              <FontAwesomeIcon icon={faAnchor} />
              <span className="ml-2">Product Traceability</span>
            </a>
          </li>
        </div>
        <div
          className="packingHouse"
          style={
            userrole?.role?._id == 0 || userrole?.role?.name == "Packing House"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <div className="sidebar-divider">
            <li
              className={`nav-item ${
                lastUrl === "recieving-at-packing-house" ? "active" : ""
              }`}
            >
              <a
                className="nav-link"
                onClick={() => {
                  navigate("recieving-at-packing-house");
                }}
              >
                <i className="fa fa-archive" aria-hidden="true"></i>
                <span className="ml-2">RM Receiving at Packing House</span>
              </a>
            </li>
          </div>
          <div className="sidebar-divider">
            <li
              className={`nav-item ${
                lastUrl === "loading-at-packing-house" ? "active" : ""
              }`}
            >
              <a
                className="nav-link"
                onClick={() => {
                  navigate("loading-at-packing-house");
                }}
              >
                <i className="fa fa-archive" aria-hidden="true"></i>
                <span className="ml-2">Operations at Packing House</span>
              </a>
            </li>
          </div>
        </div>

        <div
          className="manufacturing"
          style={
            userrole?.role?._id == 0 || userrole?.role?.name == "Manufacturer"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <div className="sidebar-divider">
            <li
              className={`nav-item ${
                lastUrl === "recieving-at-manufacturer" ? "active" : ""
              }`}
            >
              <a
                className="nav-link"
                onClick={() => {
                  navigate("recieving-at-manufacturer");
                }}
              >
                <i className="fa fa-archive" aria-hidden="true"></i>
                <span className="ml-2">Manufacturing Warehouse</span>
              </a>
            </li>
          </div>
          <div className="sidebar-divider">
            <li
              className={`nav-item ${
                lastUrl === "loading-at-manufacturer" ? "active" : ""
              }`}
            >
              <a
                className="nav-link"
                onClick={() => {
                  navigate("loading-at-manufacturer");
                }}
              >
                <i className="fa fa-archive" aria-hidden="true"></i>
                <span className="ml-2">Manufacturing & Packing</span>
              </a>
            </li>
          </div>
        </div>
        <div
          className="sidebar-divider"
          style={
            userrole?.role?._id == 0 || userrole?.role?.name == "Exporter"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <li className={`nav-item ${lastUrl === "exporter" ? "active" : ""}`}>
            <a
              className="nav-link"
              onClick={() => {
                navigate("exporter");
              }}
            >
              <i className="fa fa-upload" aria-hidden="true"></i>
              <span className="ml-2">Exporter</span>
            </a>
          </li>
        </div>
        <div
          className="sidebar-divider"
          style={
            userrole?.role?._id == 0 || userrole?.role?.name == "Importer"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <li className={`nav-item ${lastUrl === "importer" ? "active" : ""}`}>
            <a
              className="nav-link"
              onClick={() => {
                navigate("importer");
              }}
            >
              <i className="fa fa-download" aria-hidden="true"></i>
              <span className="ml-2">Importer</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default DashboardSidebar;



