import { useEffect, useState } from "react";
import { apiCall } from "../../../apicalls/apiCall";
import {
  getAllImporter,
  createImporter,
  updateItemImporter,
  deleteItemImporter,
  getAllLoadingAtManufacturerCode,
  allExporterIds,
  getAllCertificateFromDb,
  getImporterCertificate,
  uploadAFile,
  queryItemExporter,
} from "../../../apicalls/apiRoutes";
import "../../../css/sb-admin-2.min.css";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableLoader } from "../../component Main/loader";
import { timeConversion } from "../../component Main/timeConversion";
function Importer(params) {
  const [currentPage, setCurrentPage] = useState(1);
  var [postsPerPage, setPostPerPage] = useState(10);
  const [totalDataLenght, setTotalDataLenght] = useState();
  const [currentRecords, setCurrentRecord] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [rendControl, setrendControl] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [thresholdDate, setThresholdDate] = useState()
  const [dataForApiCall, setDataForApiCall] = useState({
    bookmark: "",
    limit: postsPerPage,
    page: currentPage,
    search: searchParams,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [certificates , setCertificates] = useState([])
  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  const [IdToDelete, setIdToDelete] = useState({
    id: null,
  });


   useEffect(()=>{
apiCall('get',getImporterCertificate , {bookmark : "" , limit: 200  } , true).then((result) => {
  setCertificates(result?.data?.data?.docs)
}).catch((err) => {
  toast.warn(err?.response?.data?.message)
  console.log("🚀 ~ file: importer.js:58 ~ apiCall ~ err:", err)
});
   },[])

  const [Data, setData] = useState();
  const [addNewEntryData, setAddNewEntryData] = useState({
    receivingDate: "",
    // foodsafetyplan: "",
    shipmentDate: "",
    certificateofanalysis: {},
    originport: "",
    origincountry: "",
    destinationport: "",
    destinationcountry: "",
    shipmentID: "",
    quantity: 0,
  });

  const [editEntryData, setEditEntryData] = useState({
    id: null,
    receivingDate: "",
    shipmentDate: "",
    certificateofanalysis: {
    },
    originport: "",
    origincountry: "",
    destinationport: "",
    destinationcountry: "",
    quantity: 0,
  });

  const getAllLoadingData = () => {
    setIsFetching(true);
    apiCall("post", getAllImporter, dataForApiCall, true)
      .then((result) => {
        setTotalDataLenght(result?.data?.data?.docs?.length);
        setData(result.data);

        setIsFetching(false);
      })
      .catch((err) => {
        setData(err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const storeValues = (e) => {
    setAddNewEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const storeIdsInObj = (e) => {
    const newObj = JSON.parse(e);
    addNewEntryData["receiveCode"] = newObj.receivingMaterialCode;
    addNewEntryData["materialId"] = newObj._id;
  };

  const addNewEntry = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(addNewEntryData.originport)) {
      showToastMessage("Port Origin Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (/^\d+$/.test(addNewEntryData.origincountry)) {
      showToastMessage("Country Origin Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (/^\d+$/.test(addNewEntryData.destinationport)) {
      showToastMessage("Destination Port Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (/^\d+$/.test(addNewEntryData.destinationcountry)) {
      showToastMessage("Destination Country Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (addNewEntryData?.originport?.toUpperCase() == addNewEntryData?.destinationport?.toUpperCase()) {
      showToastMessage(
        "Origin Port and Destination Port cannot be same",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (addNewEntryData.receivingDate > addNewEntryData.shipmentDate) {
      showToastMessage(" Date of shipment cannot be before the date of receiving ");
      setIsFetching(false);
      return 0;
    }
    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", createImporter, addNewEntryData, true)
      .then((res) => {
        showToastMessage("Loaded at packing Uploaded Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setAddNewEntryData({});
        setIsLoading(false);
        document.getElementById("closeModalButtonAddNew").click();
        setIsFetching(false);
        const element = document.getElementById("form1");
        element?.reset();
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const editFunctionality = (e) => {
    setEditEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const selectValues = (ele) => {
    const recievingDateFormated = timeConversion(ele?.receivingDate);
    const shipmentDate = timeConversion(ele?.shipmentDate);
    var certObj = {}

    certificates.map((certificate)=>{
      if( ele['certificateofanalysis'][certificate?._id]){
        certObj[certificate?._id] = ele['certificateofanalysis'][certificate?._id]['file'] 
      }
      
    })
getDateThreshold(ele?.shipmentID)
    setEditEntryData({
      id: ele?._id,
      receivingDate: recievingDateFormated,
      shipmentDate: shipmentDate,
      certificateofanalysis: certObj,
      originport: ele?.originport,
      origincountry: ele?.origincountry,
      destinationport: ele?.destinationport,
      destinationcountry: ele?.destinationcountry,
      quantity: ele?.quantity,
    });
  };
  const EditEntrySubmit = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(editEntryData.originport)) {
      showToastMessage("Port Origin Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (/^\d+$/.test(editEntryData.origincountry)) {
      showToastMessage("Country Origin Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (/^\d+$/.test(editEntryData.destinationport)) {
      showToastMessage("Destination Port Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (/^\d+$/.test(editEntryData.destinationcountry)) {
      showToastMessage("Destination Country Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
    else if (editEntryData?.originport?.toUpperCase() == editEntryData?.destinationport?.toUpperCase()) {
      showToastMessage(
        "Origin Port and Destination Port cannot be same",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (editEntryData.receivingDate > editEntryData.shipmentDate) {
      showToastMessage(" Date of shipment cannot be before the date of receiving ");
      setIsFetching(false);
      return 0;
    }
    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", updateItemImporter, editEntryData, true)
      .then((res) => {
        showToastMessage("Loading at packing editted Successfully", "green", 1);
        setrendControl(!rendControl);
        e.target.reset();
        setIsLoading(false);
        document.getElementById("closeModalButtonEdit").click();
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  const deleteFunctionality = (e) => {
    setIsLoading(true);
    setIsFetching(true);
    
    apiCall("post", deleteItemImporter, IdToDelete, true)
      .then((res) => {
        
        showToastMessage("Loaded at packing removed Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonDelete").click();
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  useEffect(() => {
    getAllLoadingData();
  }, [currentPage, rendControl]);

  const searchTable = (e) => {
    var input, filter, table, tr, td, i, txtValue;
    input = e.target.value;
    filter = input.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  useEffect(() => {
    setIsFetching(true);
    apiCall("get", allExporterIds, {}, true)
      .then((res) => {
        setIdArray(res.data.data.docs);
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: LoadingAtPackingHouse.js:153 ~ useEffect ~ err:",
          err
        );
        setIsFetching(false);
      });
  }, []);

  const searchFunctionality = () => {
    setrendControl(!rendControl);
    setCurrentPage(currentPage);
  };
  const checkboxFunctionality = (e, addNew) => {
    if (e.target.checked) {
      addNew
        ? (addNewEntryData["certificateofanalysis"][e.target.id] = "Yes")
        : (editEntryData["certificateofanalysis"][e.target.id] = "Yes");
    } else {
      addNew
        ? (addNewEntryData["certificateofanalysis"][e.target.id] = "No")
        : (editEntryData["certificateofanalysis"][e.target.id] = "No");
    }
  };

  const fileUploader = (e,isEdit) => {
    setIsLoading(true);
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    apiCall("post", uploadAFile, formData, true)
      .then((res) => {
        setIsLoading(false);
        isEdit ? editEntryData['certificateofanalysis'][e.target.id] =  res.data.data.Location :  addNewEntryData['certificateofanalysis'][e.target.id] = res.data.data.Location
        showToastMessage("File Uploaded Successfulyy", "green", 1);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        console.log(err);
        setIsFetching(false);
      });
  };

  const getDateThreshold = (id) =>{
    apiCall('post' , queryItemExporter , {id : id} , true).then((result) => {
      console.log("🚀 ~ file: importer.js:355 ~ apiCall ~ result:", result)
      const dataa =  new Date(result?.data?.data?.shipmentDate)?.toISOString().split('T')[0]
    setThresholdDate(dataa)
      
    }).catch((err) => {
      console.log("🚀 ~ file: importer.js:358 ~ apiCall ~ err:", err)
      
    });
  }

  useEffect(()=>{
    addNewEntryData?.shipmentID !== "" && getDateThreshold(addNewEntryData?.shipmentID)
  },[addNewEntryData?.shipmentID])


  return (
    <>
      <div>
        <div id="d-flex flex-column">
          <div>
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <h1
                      className="h3 mb-0 text-gray-800 first-heading"
                      id="usermanagement-h1"
                    >
                      Importer
                    </h1>
                  </div>
                  <div class="col-sm">
                    <div className="search d-flex butonn">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Country or port"
                        onChange={(e) => {
                          setDataForApiCall((prev) => {
                            return { ...prev, ["search"]: e.target.value };
                          });
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={searchFunctionality}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-2" style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalCenter"
                    >
                      <i className="fa fa-plus mr-2"></i>Add
                    </button>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Add New
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonAddNew"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={addNewEntry}
                                  id="form1"
                                >
                                  <div className="form-group row">
                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Shipment Id
                                      </label>
                                      <select
                                        id="shipmentID"
                                        onChange={storeValues}
                                        className="form-select"
                                      >
                                        <option
                                          cted={
                                            addNewEntryData.shipmentID
                                              ? true
                                              : false
                                          }
                                        >
                                          -- Select An Id --
                                        </option>
                                        {idArray?.length &&
                                          idArray?.map((ele) => {
                                            return (
                                              <option value={ele?._id}>
                                                {ele?.shipmentID}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving Date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="receivingDate"
                                        value={addNewEntryData?.receivingDate}
                                        placeholder="Code"
                                        min={thresholdDate}
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3 ">
                                      <label htmlFor="shipment">
                                        Shipment Date
                                      </label>
                                      <input
                                        type="date"
                                        value={addNewEntryData?.shipmentDate}
                                        className="form-control form-control-user"
                                        id="shipmentDate"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                      />
                                    </div>

                                   
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Quantity
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="quantity"
                                        min='1'
                                        value={addNewEntryData?.quantity}
                                        placeholder="quantity"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                      />
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                      <label className="ms-2">
                                        Certificate Of Analysis
                                      </label>
                                      {
                                      certificates.map((cert)=>{
                                        return(
                                        <label>{cert?.name}
                                        <input type="file" onChange={fileUploader} id={cert._id} required={cert?.required} />
                                        </label>
                                        )
                                      })
                                      }
                                      <div>
                                      </div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Origin Port
                                      </label>
                                      <input
                                        type="text"
                                        onInput={(e) => {
                                        
                                            storeValues(e);
                                         
                                        }}
                                        className="form-control form-control-user"
                                        id="originport"
                                        value={addNewEntryData?.originport}
                                        placeholder="originport"
                                       
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Origin Country
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onInput={(e) => {
                                         
                                            storeValues(e);
                                      
                                        }}
                                        value={addNewEntryData?.origincountry}
                                        id="origincountry"
                                        placeholder="Origin country"
                                       
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination Port
                                      </label>
                                      <input
                                        type="text"
                                        onInput={(e) => {
                                         
                                            storeValues(e);
                                      
                                        }}
                                        className="form-control form-control-user"
                                        id="destinationport"
                                        value={addNewEntryData?.destinationport}
                                        placeholder="destination port"
                                       
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination Country
                                      </label>
                                      <input
                                        type="text"
                                        onInput={(e) => {
                                         
                                            storeValues(e);
                                          
                                        }}
                                        className="form-control"
                                        value={
                                          addNewEntryData?.destinationcountry
                                        }
                                        id="destinationcountry"
                                        placeholder="thailand"
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary float-end   d-flex flex-wrap"
                                    type="submit"
                                    style={{ display: "flex" }}
                                  >
                                    Submit
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="editModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Edit Listing
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonEdit"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            {/* <div className="col-12 mb-3 ">
                              <label htmlFor="birthday">
                               Shipment ID
                              </label>
                              <select
                                id="shipmentID"
                                onChange={storeValues}
                                className="form-select"
                              >
                                {idArray?.length &&
                                  idArray?.map((ele) => {
                                    return (
                                      <option className="w-20" value={ele?._id}>
                                        {ele?.shipmentID}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div> */}

                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={EditEntrySubmit}
                                >
                                  <div className="form-group row">
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving Date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="receivingDate"
                                        min={thresholdDate}
                                        value={editEntryData?.receivingDate}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3 ">
                                      <label htmlFor="shipment">
                                        Shipment Date
                                      </label>
                                      <input
                                        type="date"
                                        value={editEntryData?.shipmentDate}
                                        className="form-control form-control-user"
                                        id="shipmentDate"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>

                                    {/* <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Food Safety Plan
                                      </label>
                                      <select
                                        className="form-control form-control-user"
                                        id="foodsafetyplan"
                                        value={editEntryData?.foodsafetyplan}
                                        placeholder="Food safety Plan"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      >
                                        <option>-- Select an Option --</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    </div> */}
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Quantity
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="quantity"
                                        min='1'
                                        value={editEntryData?.quantity}
                                        placeholder="quantity"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                      <label className="ms-2">
                                        Certificate Of Analysis
                                      </label>
                                      {
                                      certificates.map((cert)=>{
                                        return(
                                          <>
                                        <label>{cert?.name}
                                        <input type="file" onChange={(e)=>{fileUploader(e, true)}} id={cert._id} />
                                        </label>
                                        {
                                          editEntryData['certificateofanalysis'][cert?._id] ? <a href={editEntryData['certificateofanalysis'][cert?._id]['file']}> Show file </a> : ""
                                        }
                                        </>
                                        )
                                      })
                                      }
                                      <div>
                                      </div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Origin Port
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="originport"
                                        value={editEntryData?.originport}
                                        placeholder="originport"
                                        onInput={(e) => {
                                        
                                            editFunctionality(e);
                                          
                                        }}                             
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Origin Country
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={editEntryData?.origincountry}
                                        id="origincountry"
                                        placeholder="Origin country"
                                        onInput={(e) => {
                                        
                                            editFunctionality(e);
                                        
                                        }}
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination Port
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="destinationport"
                                        value={editEntryData?.destinationport}
                                        placeholder="destination port"
                                        onInput={(e) => {
                                          
                                            editFunctionality(e);
                                          
                                        }}
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination Country
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={
                                          editEntryData?.destinationcountry
                                        }
                                        id="destinationcountry"
                                        placeholder="thailand"
                                        onInput={(e) => {
                                          
                                            editFunctionality(e);
                                          
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary float-end   d-flex flex-wrap"
                                    type="submit"
                                    style={{ display: "flex" }}
                                  >
                                    Update
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary"></h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive custom-table">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered fold-table"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0"
                          >
                            <thead>
                              <tr>
                                <th rowspan="2" className="nowrap">
                                  Date of Receiving in import country
                                </th>
                                <th rowspan="2">Product Batch Code</th>
                                <th rowspan="2">Food Safety Plan Available</th>
                                <th rowspan="2" align="center">
                                  Certificate of Analysis
                                </th>{" "}
                                <th rowspan="2">Date of Shipment</th>
                                <th rowspan="2">Origin Port</th>
                                <th rowspan="2">Origin Country</th>
                                <th rowspan="2">Quantity</th>
                                <th rowspan="2">Destination Port</th>
                                <th rowspan="2">Destination Country</th>
                                <th rowspan="2">Shipment ID</th>
                                <th rowspan="2">Receiving ID</th>
                                <th rowspan="2" class="nowrap">Edit Actions</th>
                              </tr>
                         
                            </thead>
                            {isFetching ? (
                              <tr>
                                <td colSpan="11">
                                  {" "}
                                  <TableLoader />
                                </td>
                              </tr>
                            ) : Data?.data?.docs?.length ? (
                              <tbody>
                                {Data?.data?.docs.map((ele, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{ele?.receivingDate}</td>
                                      <td>{ele?.productBatchCode}</td>
                                      <td><a href={ele?.foodsafetyplan} target="blank">View File</a> </td>
                                      <td>
                                  {
                                    certificates?.map((certificate)=>{
                                      return(
                                      ele['certificateofanalysis'][certificate?._id] ? <a href={ele['certificateofanalysis'][certificate?._id]['file'] }>{certificate?.name}</a> : ""
                                      )
                                    })
                                  }
                                      </td>
                                      <td>{ele?.shipmentDate}</td>
                                      <td>{ele?.originport}</td>
                                      <td>{ele?.origincountry}</td>
                                      <td>{ele?.quantity}</td>
                                      <td>{ele?.destinationport}</td>
                                      <td>{ele?.destinationcountry}</td>
                                      <td>{ele?.shipmentID}</td>
                                      <td>{ele?.receivingID}</td>
                                  
                                      <td className="nowrap">
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#editModalCenter"
                                        >
                                          <i
                                            className="fa fa-edit"
                                            id={ele._id}
                                            onClick={() => {
                                              selectValues(ele);
                                            }}
                                          />
                                        </a>
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                        >
                                          <i
                                            className="fa fa-trash-o m-1"
                                            id={ele._id}
                                            onClick={(e) => {
                                              setIdToDelete({
                                                id: e.target.id,
                                              });
                                            }}
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tr>
                                <td colSpan="12">No Record Found</td>
                              </tr>
                            )}
                          </table>
                          <ReactPaginate
                  onPageChange={paginate}
                  pageCount={Math.ceil(totalDataLenght / postsPerPage)}
                  previousLabel={"<"}
                  nextLabel={">"}
                  containerClassName={"pagination"}
                  pageLinkClassName={"page-number"}
                  previousLinkClassName={"page-number"}
                  nextLinkClassName={"page-number"}
                  activeLinkClassName={"active"}
                />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete an Entry
                </h5>
                <button
                  className="close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure ?</div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  Cancel
                </button>
                <a
                  className="btn btn-primary"
                  style={{ display: "flex" }}
                  onClick={deleteFunctionality}
                >
                  Delete
                  <i
                    style={
                      isLoading ? { display: "block" } : { display: "none" }
                    }
                    className="fa fa-spinner fa-spin m-1"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Importer;
