import DashboardSidebar from "./components/sidebar";
import UserManagementComponent from "./components/userManagementComponent";
import HeaderDashboard from "./components/header";
import Footer from "./components/footer";
function UserManagement(params) {
  return (
    <div className="page-top">
      <div id="wrapper">
        <DashboardSidebar />
        <div id="content-wrapper" class="d-flex flex-column">
          <HeaderDashboard />
          <UserManagementComponent />
          <Footer />
        </div>
      </div>
      
    </div>
  );
}
export default UserManagement;
