import { useEffect, useState } from "react";
import { apiCall } from "../../../apicalls/apiCall";
import {
  getAllLoadingAtManufacturer,
  updateLoadingAtManufacturer,
  deleteLoadingAtManufacturer,
  queryLoadingAtManufacturer,
  createLoadingAtManufacturer,
  getAllLoadingAtManufacturerCode,
  getAllIdsManufacturerWarehouse,
  uploadAFile,
  getManfacturingAndPackingCertificate,
  getAlltemsManufacturerPackingCertificate,
  searchManufacturerWareHouse,
} from "../../../apicalls/apiRoutes";
import "../../../css/sb-admin-2.min.css";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableLoader } from "../../component Main/loader";
import { timeConversion } from "../../component Main/timeConversion";
function LoadingAtManufacturer(params) {
  const [currentPage, setCurrentPage] = useState(1);
  var [postsPerPage, setPostPerPage] = useState(10);
  const [totalDataLenght, setTotalDataLenght] = useState();
  const [currentRecords, setCurrentRecord] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [rendControl, setrendControl] = useState(false);
  const [ingredientsArray, setingredientsArray] = useState([]);
  const [dataForApiCall, setDataForApiCall] = useState({
    bookmark: "",
    limit: postsPerPage,
    page: currentPage,
    search: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  let ingredients = [];
  let editIngredients = [];
  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  const [getAllIds, setAllIds] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [totalIngredients, setTotalIngredients] = useState({});
  useEffect(() => {
    allIdsGetter();
  }, []);
  const allIdsGetter = () => {
    setIsFetching(true);
    apiCall("get", getAllIdsManufacturerWarehouse, {}, true)
      .then((res) => {
        setAllIds(res?.data?.data?.docs);
        console.log(res)
        setIsFetching(false);
      })
      .then((Err) => {
        console.log("Err", Err);
        setIsFetching(false);
      });
  };

  const [IdToDelete, setIdToDelete] = useState({
    id: null,
  });


  const [Data, setData] = useState();
  const [maxValue, setMaxValue] = useState(0);
  const [addNewEntryData, setAddNewEntryData] = useState({
    startDate: "",
    startTime: "",
    product: "",
    code: "",
    market: "",
    facilityNo: "",
    productionLineNo: "",
    ingredients: [],
    blenderNo: "",
    mixerNo: "",
    processingTechnology: "",
    fillingpackingno: "",
    dateoffillingpacking: "",
    packagingMaterialTest: "",
    finishproductTest: "",
    workinprocessCertificate: "",
    timeoffillingpacking: "",
    certificateofanalysis: {},
    foodsafetyplan: "",
    endDate: "",
    endTime: "",
    productionQuantity: 0,
  });
  const [editEntryData, setEditEntryData] = useState({
    id: "",
    startDate: "",
    startTime: "",
    product: "",
    code: "",
    market: "",
    facilityNo: "",
    productionLineNo: "",
    ingredients: [],
    blenderNo: "",
    mixerNo: "",
    processingTechnology: "",
    fillingpackingno: "",
    dateoffillingpacking: "",
    packagingMaterialTest: "",
    finishproductTest: "",
    workinprocessCertificate: "",
    timeoffillingpacking: "",
    certificateofanalysis: {},
    foodsafetyplan: "",
    endDate: "",
    endTime: "",
    productionQuantity: 0,
  });


  const getAllLoadingData = () => {
    setIsFetching(true);
    apiCall("post", getAllLoadingAtManufacturer, dataForApiCall, true)
      .then((result) => {
        setTotalDataLenght(result?.data?.data?.docs?.length);
        setData(result.data);
        result?.data?.data?.docs?.map((ele, index) => {
          setTotalIngredients((prev) => {
            return { ...prev, [index]: ele?.ingredients.length };
          });
        });
        setIsFetching(false);
      })
      .catch((err) => {
        setData(err);
        setIsLoading(false);
        setIsFetching(false);
      });

    const arr = Object.values(totalIngredients);
    const max = Math.max(...arr);
    setMaxValue(max);
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const storeValues = (e) => {
    setAddNewEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const addNewEntry = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(addNewEntryData.product)) {
      showToastMessage("Product Name Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
   else if (/^\d+$/.test(addNewEntryData.code)) {
      showToastMessage("Product Code Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
else if (/^\d+$/.test(addNewEntryData.market)) {
  showToastMessage("Market Cannot Only contain numbers", "red", 2);
setIsFetching(false)
  return 0
}
else if (/^\d+$/.test(addNewEntryData.processingTechnology)) {
  showToastMessage("Processing technology Cannot Only contain numbers", "red", 2);
setIsFetching(false)
  return 0
}
    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", createLoadingAtManufacturer, addNewEntryData, true)
      .then((res) => {
        showToastMessage("Loaded at packing Uploaded Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonAddNew").click();
        setIsFetching(false);
        const element = document.getElementById("form1");
        element?.reset();
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const editFunctionality = (e) => {
    setEditEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const selectValues = (ele) => {
    const formattedStartDate = timeConversion(ele?.startDate);
    const formattedEndDate = timeConversion(ele?.endDate);
    const fillingdate = timeConversion(ele?.dateoffillingpacking);

    const KeysCeificates = Object.keys(ele.certificateofanalysis);
    const allCertificatesofAnalysis = {}

    for(const keyCertificate of KeysCeificates){
      const searchIndex = certificate.findIndex((c) => c._id==keyCertificate);

      if(searchIndex!=-1){
        allCertificatesofAnalysis[keyCertificate] = ele.certificateofanalysis[keyCertificate]['file']
      }
    }
    setEditEntryData({
      id: ele?.id,
      startDate: formattedStartDate,
      startTime: ele?.startTime,
      product: ele?.product,
      code: ele?.loadingatManufacturerCode,
      market: ele?.market,
      facilityNo: ele?.facilityNo,
      productionLineNo: ele?.productionLineNo,
      ingredients: ele?.ingredients?.map((element) => {
        return element?.id;
      }),
      blenderNo: ele?.blenderNo,
      mixerNo: ele?.mixerNo,
      processingTechnology: ele?.processingTechnology,
      packagingMaterialTest: ele?.packagingMaterialTest,
      finishproductTest: ele?.finishProductTest,
      workinprocessCertificate: ele?.workinprocessCertificate,
      fillingpackingno: ele?.fillingpackingno,
      dateoffillingpacking: fillingdate,
      timeoffillingpacking: ele?.timeoffillingpacking,
      certificateofanalysis: allCertificatesofAnalysis,
      foodsafetyplan: ele?.foodsafetyplan,
      endDate: formattedEndDate,
      endTime: ele?.endTime,
      productionQuantity: ele?.productionQuantity,
    });
  };
  const EditEntrySubmit = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(editEntryData.product)) {
      showToastMessage("Product Name Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
   else if (/^\d+$/.test(editEntryData.code)) {
      showToastMessage("Product Code Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
else if (/^\d+$/.test(editEntryData.market)) {
  showToastMessage("Market Cannot Only contain numbers", "red", 2);
setIsFetching(false)
  return 0
}
else if (/^\d+$/.test(editEntryData.processingTechnology)) {
  showToastMessage("Processing technology Cannot Only contain numbers", "red", 2);
setIsFetching(false)
  return 0
}

    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", updateLoadingAtManufacturer, editEntryData, true)
      .then((res) => {
        showToastMessage(
          "Loading at manufacturers editted Successfully",
          "green",
          1
        );
        document.getElementById("closeModalButtonEdit").click();
        setrendControl(!rendControl);
        e.target.reset();
        setIsLoading(false);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  const deleteFunctionality = (e) => {
    setIsLoading(true);
    setIsFetching(true);
    apiCall("post", deleteLoadingAtManufacturer, IdToDelete, true)
      .then((res) => {
        showToastMessage("Loaded at packing removed Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonDelete").click();
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  useEffect(() => {
    getAllLoadingData();
  }, [currentPage, rendControl]);

  useEffect(() => {
    setIsFetching(true);
    apiCall("get", getAllLoadingAtManufacturerCode, {}, true)
      .then((res) => {
        setIdArray(res.data.data.docs);
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: LoadingAtPackingHouse.js:153 ~ useEffect ~ err:",
          err
        );
        setIsFetching(false);
      });
  }, []);



  const [thresholdDate, setThresholdDate] = useState('') 
  let dataArray = []
  useEffect(()=>{
    if(thresholdDate !== "") {
       setAddNewEntryData((prev)=>{
      return{...prev , ['startDate'] : ''}
       
    });
    setEditEntryData((prev)=>{
      return{...prev , ['startDate'] : ''}
    }) 
    document.querySelector('#startDate').value= ''
  }

  },[thresholdDate])
  const checkBoxIngredients = (e) => {
    
    apiCall('post', searchManufacturerWareHouse, {id: e.target.id} ,true).then((result) => {
      const dataa = new Date(result?.data?.data?.receivingDate)?.toISOString().split('T')[0]
      if (e.target.checked == true) {
        ingredients.push(e.target.id);
        addNewEntryData["ingredients"] = ingredients;
        dataArray.push(dataa)
      } else {
        const position = ingredients.indexOf(e.target.id);
        ingredients.splice(position, 1);
        addNewEntryData["ingredients"] = ingredients;
        const pos = dataArray?.indexOf(dataa)
        dataArray?.splice(pos, 1)
      }
      var dateObjects = dataArray.map(function(dateString) {
        // Split the date string and create a Date object
        var parts = dateString.split("-");
        return new Date(parts[0], parts[1] - 1, parts[2]);
    });
    
    // Find the maximum date
    var maxDate = new Date(Math.max.apply(null, dateObjects));
    
    // Convert max date back to string if needed
    var maxDateStr = maxDate.toISOString().split('T')[0];
    setThresholdDate(maxDateStr)

    }).catch((err) => {
      console.log("🚀 ~ file: loadingAtManufacturer.js:344 ~ apiCall ~ err:", err)
    });
   
  };

  const fileUploader = (e, check) => {
    setIsLoading(true);
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    apiCall("post", uploadAFile, formData, true)
      .then((res) => {
        setIsLoading(false);
        check
          ? (addNewEntryData["certificateofanalysis"][e.target.id] =
              res.data.data.Location)
          : (addNewEntryData[e.target.id] = res.data.data.Location);
        showToastMessage("File Uploaded Successfulyy", "green", 1);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        console.log(err);
        setIsFetching(false);
      });
  };

  const fileUploaderForEdit = (e, name, certificate) => {
    setIsLoading(true);
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    apiCall("post", uploadAFile, formData, true)
      .then((res) => {
        setIsLoading(false);
        certificate
          ? (editEntryData["certificateofanalysis"][certificate] =
              res.data.data.Location)
          : (editEntryData[name] = res.data.data.Location);
        showToastMessage("File Uploaded Successfulyy", "green", 1);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        console.log(err);
        setIsFetching(false);
      });
  };

  const getAllCertificates = async () => {
    apiCall("get", getManfacturingAndPackingCertificate, {}, true)
      .then((result) => {
        setCertificate(result.data.data.docs);
      })
      .catch((err) => {
        console.log(err, "we");
      });
  };

  useEffect(() => {
    getAllCertificates();
  }, []);

  return (
    <>
      <div>
        <div id="d-flex flex-column">
          <div>
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <h1
                      className="h3 mb-0 text-gray-800 first-heading"
                      id="usermanagement-h1"
                    >
                      Manufacturing & Packing
                    </h1>
                  </div>
                  <div className="col-sm">
                    <div className="search d-flex butonn">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search recieving code"
                        onChange={(e) => {
                          dataForApiCall.search = e.target.value;
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={async () => {
                          await setCurrentPage(1);
                          setrendControl(!rendControl);
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-2" style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalCenter"
                    >
                      <i className="fa fa-plus mr-2"></i>Add
                    </button>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Add New
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonAddNew"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={addNewEntry}
                                  id="form1"
                                >
                                  <div className="form-group row">
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Start date
                                      </label>
                                      <input
                                        type="date"
                                        id="startDate"
                                        min={thresholdDate}
                                        onChange={storeValues}
                                        className="form-control form-control-user"
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3 ">
                                      <label htmlFor="birthday">
                                        Start time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="startTime"
                                        step="1"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Product Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="product"
                                        placeholder="Product Name"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>

                                  

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Product Code
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="code"
                                        placeholder="Product Code"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Market
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="market"
                                        placeholder="market"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Work in process certificate
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control form-control-user"
                                        id="workinprocessCertificate"
                                        placeholder="Work in process certificate"
                                        onChange={(e) => {
                                          fileUploader(e, false);
                                        }}
                                        accept="application/pdf,application/vnd.ms-excel"
                                      />
                                      {addNewEntryData.workinprocessCertificate ==
                                      "" ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            window.open(
                                              addNewEntryData.workinprocessCertificate,
                                              "_blank"
                                            );
                                          }}
                                          className="cursor"
                                        >
                                          View File
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Finish Product Test
                                      </label>
                                      <input
                                        type="file"
                                        accept="application/pdf,application/vnd.ms-excel"
                                        className="form-control form-control-user"
                                        id="finishproductTest"
                                        placeholder="finish Product Test"
                                        onChange={(e) => {
                                          fileUploader(e, false);
                                        }}
                                        required
                                      />
                                      {addNewEntryData.finishproductTest ==
                                      "" ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            window.open(
                                              addNewEntryData.finishproductTest,
                                              "_blank"
                                            );
                                          }}
                                        >
                                          View File
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Packaging Material Test
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control form-control-user"
                                        id="packagingMaterialTest"
                                        placeholder="packaging Material Test"
                                        onChange={(e) => {
                                          fileUploader(e, false);
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Ingredient
                                      </label>
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-light w-100 text-start "
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          style={{
                                            backgroundColor: "transparent",
                                            borderColor: "#D1D3E2",
                                            color: "#6D767E",
                                          }}
                                        >
                                          Ingredients
                                        </button>
                                        <ul
                                          className="dropdown-menu w-100 text-end p-3 "
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {getAllIds?.map((element, index) => {
                                            return (
                                              <div className="d-flex w-100 m-auto">
                                                <li
                                                  className=""
                                                  style={{
                                                    fontSize: "1rem",
                                                    textAlign: "left",
                                                    color: "#6D767E",
                                                    width: "50%",
                                                  }}
                                                  key={index}
                                                  id={element?._id}
                                                >
                                                  {element?.raw_material}
                                                </li>
                                              
                                                <li
                                                style={{
                                                  fontSize: "0.8rem",
                                                  textAlign: "left",
                                                  color: "#6D767E",
                                                  width: "100%",
                                                }}>
                                                  {element?.rawmaterial_receive_code }
                                                </li>
                                                <div
                                                  style={{ width: "10%" }}
                                                  className="mt-1"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={element?._id}
                                                    onClick={
                                                      checkBoxIngredients
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Facility No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="facilityNo"
                                        placeholder="facility number"
                                        onChange={storeValues}
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <label htmlFor="exampleInputEmail1">
                                        Production Line No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="productionLineNo"
                                        placeholder="productionLineNo"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-6  mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Blender No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="blenderNo"
                                        placeholder="blenderNo"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6  mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Mixer No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="mixerNo"
                                        placeholder="mixerNo"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6  mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Processing Technology
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="processingTechnology"
                                        placeholder="processingTechnology"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6  mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Filling Packing No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="fillingpackingno"
                                        placeholder="fillingpackingno"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6  mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Date of Filling Packing
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="dateoffillingpacking"
                                        placeholder="dateoffillingpacking"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6  mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Time of Filling Packing
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="timeoffillingpacking"
                                        placeholder="timeoffillingpacking"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        step="1"
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="ms-2"
                                      >
                                        Certificate of analysis
                                      </label>
                                      {certificate?.map((ele) => {
                                        return (
                                          <div className="col-12 mb-3">
                                            <div className="mb-1 choosefile">
                                              <label>{ele?.name}</label>
                                              <input
                                                type="file"
                                                id={ele?._id}
                                                name={ele?.name}
                                                onChange={(e) => {
                                                  fileUploader(e, true);
                                                }}
                                                required={ele?.required}
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <div className="col-12 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Food Safety Plan
                                      </label>
                                      <input
                                        type="file"
                                        id="foodsafetyplan"
                                        className="ml-2"
                                        onChange={(e) => {
                                          fileUploader(e, false);
                                        }}
                                        accept="application/pdf,application/vnd.ms-excel"
                                      />
                                    </div>
                                    <div className="co-l2 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        End date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="endDate"
                                        onChange={storeValues}
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        End Time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="endTime"
                                        onChange={storeValues}
                                        step="1"
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Production Quantity
                                      </label>
                                      <input
                                        type="number"
                                        min="1"
                                        id="productionQuantity"
                                        className="form-control form-control-user"
                                        placeholder="productionQuantity"
                                        onChange={storeValues}
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary float-end"
                                    type="submit"
                                    style={{ display: "flex" }}
                                  >
                                    Submit
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="editModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Edit Listing
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonEdit"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={EditEntrySubmit}
                                >
                                  <div className="form-group row">
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Start date
                                      </label>
                                      <input
                                        type="date"
                                        id="startDate"
                                        onChange={editFunctionality}
                                        value={editEntryData?.startDate}
                                        className="form-control form-control-user"
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3 ">
                                      <label htmlFor="birthday">
                                        Start time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="startTime"
                                        step="1"
                                        value={editEntryData?.startTime}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Product Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="product"
                                        placeholder="Product Name"
                                        value={editEntryData?.product}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Product Code
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="code"
                                        placeholder="Product Code"
                                        value={editEntryData?.code}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Market
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="market"
                                        placeholder="market"
                                        value={editEntryData?.market}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 ">
                                      <label htmlFor="exampleInputEmail1">
                                        Facility No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="facilityNo"
                                        onChange={editFunctionality}
                                        value={editEntryData?.facilityNo}
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <label htmlFor="workinprocessCertificate1">
                                        <b>Work in process certificate</b>
                                        <br />
                                        Change File
                                      </label>
                                      <a href={editEntryData?.workinprocessCertificate} target="_blank">
                                    
                                        View File
                                     
                                      </a>
                                      <input
                                        type="file"
                                        className="form-control form-control-user"
                                        id="workinprocessCertificate1"
                                        style={{ visibility: "hidden" }}
                                        placeholder="Work in process certificate"
                                        accept="application/pdf,application/vnd.ms-excel"
                                        onChange={(e) => {
                                          fileUploaderForEdit(
                                            e,
                                            "workinprocessCertificate",
                                            false
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6 ">
                                      <label htmlFor="finishproductTest1">
                                        <b> Finish Product Test </b>
                                        <br />
                                        Change File
                                      </label>
                                      <br />
                                      <a href={editEntryData?.finishproductTest} target="_blank">
                                     
                                        View File
                                    
                                      </a>
                                      <input
                                        type="file"
                                        className="form-control form-control-user"
                                        id="finishproductTest1"
                                        style={{ visibility: "hidden" }}
                                        accept="application/pdf,application/vnd.ms-excel"
                                        placeholder="finish Product Test"
                                        onChange={(e) => {
                                          fileUploaderForEdit(
                                            e,
                                            "finishproductTest",
                                            false
                                          );
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-12 ">
                                      <label htmlFor="packingMaterial">
                                        <b>Packaging Material Test</b>
                                        <br />
                                        Change File
                                      </label>
                                      <br />
                                      <a href={editEntryData?.packagingMaterialTest} target="_blank" > 
                                    
                                        View File
                                    
                                      </a>
                                      <input
                                        type="file"
                                        className="form-control form-control-user"
                                        id="packingMaterial"
                                        placeholder="packaging Material Test"
                                        style={{ visibility: "hidden" }}
                                        onChange={(e) => {
                                          fileUploaderForEdit(
                                            e,
                                            "packagingMaterialTest",
                                            false
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Production Line No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="productionLineNo"
                                        placeholder="productionLineNo"
                                        value={editEntryData?.productionLineNo}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Blender No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="blenderNo"
                                        value={editEntryData?.blenderNo}
                                        placeholder="blenderNo"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Mixer No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="mixerNo"
                                        placeholder="mixerNo"
                                        value={editEntryData?.mixerNo}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Processing Technology
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="processingTechnology"
                                        value={
                                          editEntryData?.processingTechnology
                                        }
                                        placeholder="processingTechnology"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Filling Packing No
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-user"
                                        id="fillingpackingno"
                                        placeholder="fillingpackingno"
                                        value={editEntryData?.fillingpackingno}
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Date of Filling Packing
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="dateoffillingpacking"
                                        value={
                                          editEntryData?.dateoffillingpacking
                                        }
                                        placeholder="dateoffillingpacking"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Time Of Filling
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="timeoffillingpacking"
                                        step="1"
                                        placeholder="timeoffillingpacking"
                                        value={
                                          editEntryData?.timeoffillingpacking
                                        }
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Production Quantity
                                      </label>
                                      <input
                                        type="number"
                                        min="1"
                                        id="productionQuantity"
                                        className="form-control form-control-user"
                                        placeholder="productionQuantity"
                                        value={
                                          editEntryData?.productionQuantity
                                        }
                                        onChange={editFunctionality}
                                      />
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="ms-2"
                                      >
                                        Certificate of analysis
                                      </label>
                                      {certificate?.map((ele) => {
                                        return (
                                          <div className="col-12 mb-3">
                                            <div className="mb-1 choosefile">
                                              <label>{ele?.name}</label>
                                              <input
                                                type="file"
                                                id={ele?._id}
                                                name={ele?.name}
                                                onChange={(e) => {
                                                  fileUploaderForEdit(
                                                    e,
                                                    true,
                                                    ele?._id
                                                  );
                                                }}
                                               
                                              />
                                              {
                                                editEntryData['certificateofanalysis'][ele?._id] ? 
                                              
                                              <a href={editEntryData['certificateofanalysis'][ele?._id]} > View file</a>
                                            : ""
                                            }
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <div className="col-12 mb-3">
                                      <label
                                        htmlFor="foodsaferty"
                                        className="mr-5 ms-2"
                                      >
                                        <b>Food safety Plan</b>
                                        <br />
                                        Change File
                                      </label>
                                      <a href={editEntryData?.foodsafetyplan} target="_blank" > 
                                     
                                        View File
                                   
                                      </a>
                                      <input
                                        type="file"
                                        className="form-control form-control-user"
                                        id="foodsaferty"
                                        style={{ visibility: "hidden" }}
                                        placeholder="Work in process certificate"
                                        accept="application/pdf,application/vnd.ms-excel"
                                        onChange={(e) => {
                                          fileUploaderForEdit(
                                            e,
                                            "foodsafetyplan",
                                            false
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                      <label>Ingredients</label>
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-light w-100 text-start "
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          style={{
                                            backgroundColor: "transparent",
                                            borderColor: "#D1D3E2",
                                            color: "#6D767E",
                                          }}
                                        >
                                          Ingredients
                                        </button>
                                        <ul
                                          className="dropdown-menu w-100 text-end p-3 "
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {getAllIds?.map((element, index) => {
                                            const check =
                                              editEntryData?.ingredients.indexOf(
                                                element?._id
                                              ) !== -1;
                                            return (
                                              <div className="d-flex w-100 m-auto">
                                                <li
                                                  className=""
                                                  style={{
                                                    fontSize: "1rem",
                                                    textAlign: "left",
                                                    color: "#6D767E",
                                                    width: "70%",
                                                  }}
                                                  key={index}
                                                  id={element?._id}
                                                >
                                                  {element?.raw_material}
                                                </li>
                                                <li style={{paddingLeft:'10px'}}>
                                                  {element?.rawmaterial_receive_code}
                                                </li>
                                                <div
                                                  style={{ width: "20%" }}
                                                  className="mt-1"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={element?._id}
                                                    checked={check}
                                                    onClick={
                                                      checkBoxIngredients
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        End date
                                      </label>
                                      <input
                                        type="date"
                                        value={editEntryData?.endDate}
                                        className="form-control form-control-user"
                                        id="endDate"
                                        onChange={editFunctionality}
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        End Time
                                      </label>
                                      <input
                                        type="time"
                                        value={editEntryData?.endTime}
                                        className="form-control form-control-user"
                                        id="endTime"
                                        step="1"
                                        onChange={editFunctionality}
                                      />
                                    </div>
                                 
                                  </div>
                                  <button
                                    className="btn btn-primary float-end d-flex flex-wrap"
                                    type="submit"
                                    style={{ display: "flex" }}
                                    onClick={EditEntrySubmit}
                                  >
                                    Update
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary"></h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive custom-table">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered fold-table"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0"
                          >
                            <thead>
                              <tr>
                                <th rowSpan="2">Start Date</th>
                                <th rowSpan="2">Start Time</th>
                                <th rowSpan="2">Product</th>
                                <th rowSpan="2">Market</th>
                                <th rowSpan="2">Facility No</th>
                                <th rowSpan="2">Production Line No</th>
                                <th rowSpan="2">Ingredients</th>
                                <th rowSpan="2">Certificate of Analysis</th>
                                <th rowSpan="2">Blender No</th>
                                <th rowSpan="2">Mixer No</th>
                                <th rowSpan="2" className="nowrap">
                                  Working in Progress
                                </th>
                                <th rowSpan="2">Processing Technology</th>
                                <th rowSpan="2">Filling Packing No</th>
                                <th rowSpan="2" className="nowrap">
                                  Date of Filling Packing
                                </th>
                                <th rowSpan="2" className="nowrap">
                                  {" "}
                                  Product Batch Code
                                </th>
                                <th rowSpan="2" className="nowrap">
                                  Product Code{" "}
                                </th>
                                <th rowSpan="2">Production Quantity</th>
                                <th rowSpan="2" className="nowrap">
                                  Packing Material Test
                                </th>
                                <th rowSpan="2" className="nowrap">
                                  Finish Product Testing
                                </th>
                                <th rowSpan="2" className="nowrap">
                                  End Date
                                </th>
                                <th rowSpan="2" className="nowrap">
                                  End Time
                                </th>
                                <th rowSpan="2" className="nowrap">
                                  {" "}
                                  Food Safety Plan
                                </th>
                                <th rowSpan="2">
                                  {" "}
                                  Loading at Manufacturer Code
                                </th>
                                <th rowSpan="2" className="nowrap">
                                  Edit Action
                                </th>
                              </tr>
                            </thead>
                            {isFetching ? (
                              <tr>
                                <td colSpan="20">
                                  {" "}
                                  <TableLoader />
                                </td>
                              </tr>
                            ) : Data?.data?.docs?.length ? (
                              <tbody>
                                {Data?.data?.docs.map((ele, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{ele?.startDate}</td>
                                      <td>{ele?.startTime}</td>
                                      <td>{ele?.product}</td>
                                      <td>{ele?.market}</td>
                                      <td>{ele?.facilityNo}</td>
                                      <td>{ele?.productionLineNo}</td>
                                      <td>
                                        {ele?.ingredients?.map((elements) => {
                                          return (
                                            <span>
                                              {elements?.raw_material},
                                            </span>
                                          );
                                        })}
                                      </td>
                                      <td>
                                        {certificate.length !== 0
                                          ? certificate.map((cert) => {
                                              return ele[
                                                "certificateofanalysis"
                                              ][cert?._id] ? (
                                                <>
                                                  <p>{cert?.name}</p>
                                                  <a
                                                    target="_blank"
                                                    href={
                                                      ele[
                                                        "certificateofanalysis"
                                                      ][cert?._id]['file']
                                                    }
                                                  >
                                                    Show File
                                                  </a>
                                                  | 
                                                </>
                                              ) : (
                                                "-"
                                              );
                                            })
                                          : "-"}
                                      </td>

                                      <td>{ele?.blenderNo}</td>
                                      <td>{ele?.mixerNo}</td>
                                      <td>
                                        <a
                                          href={ele?.workinprocessCertificate}
                                          target="blank"
                                        >
                                          View File
                                        </a>
                                      </td>
                                      <td>{ele?.processingTechnology}</td>
                                      <td>{ele?.fillingpackingno}</td>
                                      <td>{ele?.dateoffillingpacking}</td>
                                      <td>{ele?.productBatchCode}</td>
                                      <td>{ele?.productcode} </td>
                                      <td>{ele?.productionQuantity}</td>
                                      <td>
                                        <a
                                          href={ele?.packagingMaterialTest}
                                          target="blank"
                                        >
                                          View File
                                        </a>
                                      </td>
                                      <td>
                                        <a
                                          href={ele?.finishProductTest}
                                          target="blank"
                                        >
                                          View File
                                        </a>
                                      </td>
                                      <td>{ele?.endDate}</td>
                                      <td>{ele?.endTime}</td>
                                      <td>
                                        <a
                                          href={ele?.foodsafetyplan}
                                          target="blank"
                                        >
                                          View File
                                        </a>
                                      </td>
                                      <td>{ele?.loadingatManufacturerCode}</td>

                                      <td>
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#editModalCenter"
                                        >
                                          <i
                                            className="fa fa-edit"
                                            id={ele._id}
                                            onClick={() => {
                                              selectValues(ele);
                                            }}
                                          />
                                        </a>
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                        >
                                          <i
                                            className="fa fa-trash-o m-1"
                                            id={ele.id}
                                            onClick={(e) => {
                                              setIdToDelete({
                                                id: e.target.id,
                                              });
                                            }}
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tr>
                                <td colSpan="12">No Record Found</td>
                              </tr>
                            )}
                          </table>
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={Math.ceil(
                              totalDataLenght / postsPerPage
                            )}
                            previousLabel={"<"}
                            nextLabel={">"}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-number"}
                            previousLinkClassName={"page-number"}
                            nextLinkClassName={"page-number"}
                            activeLinkClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete an Entry
                </h5>
                <button
                  className="close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure ?</div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <a
                  className="btn btn-primary"
                  style={{ display: "flex" }}
                  onClick={deleteFunctionality}
                >
                  Delete{" "}
                  <i
                    style={
                      isLoading ? { display: "block" } : { display: "none" }
                    }
                    className="fa fa-spinner fa-spin m-1"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoadingAtManufacturer;
