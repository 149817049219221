import "./component.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addUser,
  editUser,
  getAllUserData,
  deleteUser,
  changeUserPassword,
  getUserRoles,
  getAllNetworks,
} from "../../../apicalls/apiRoutes";
import { apiCall } from "../../../apicalls/apiCall";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { TableLoader } from "../../component Main/loader";
import { sendOTP } from "./functions/getItemsRecievingpacking";

function UserManagementComponent(params) {
  const [userRoles, setUserRoles] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  const getUserRolesFromDB = () => {
    apiCall("get", getUserRoles, {}, false)
      .then((res) => {
        setUserRoles(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserRolesFromDB();
  }, []);

  // pagination functionality

  const [reRendListingData, setReRendListingData] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  var [postsPerPage, setPostPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [totalDataLenght, setTotalDataLenght] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [idToDelete, setIdToDelete] =useState(0)
  const [code, setCode] = useState(0)
  const [dataForUserDBCall, setDataForUserDBCall] = useState({
    model: "",
    bookmark: "",
    limit: postsPerPage,
    page: 1,
    search: "",
  });
  const paginate = ({ selected }) => {
    dataForUserDBCall.page = selected+1
    setCurrentPage(selected + 1);
  };

  const changePasswordFunction = (e) => {
    setNewPassword(e.target.value);
  };

  const changePasswordFormSubmission = (e) => {
    e.preventDefault();
    const confirmPass = document.querySelector("#confirmPassword").value;
    if (confirmPass != newPassword) {
      showToastMessage("Password and confirm passsword not match", "red", 2);
    } else if (newPassword.includes(" ")) {
      showToastMessage("White Spaces in Password are not allowed", "red", 2);
    } else {
      setIsFetching(true)
      apiCall("post", changeUserPassword, {
        id:selectedId,
        newPassword: newPassword,
            ...(editUserData?.role?.name == "Admin" && {code: code})
      })
        .then((res) => {
          if (res.status == 200) {
            showToastMessage("Password changed Successfully", "green", 1);
            const button = document.getElementById("closeChangePasswordModal");
            button.click();
            setIsFetching(false)
          }
        })
        .catch((err) => {
          showToastMessage(err?.response?.data?.message, "red", 2);
          setIsFetching(false)
        });
    }
  };

  const [newUserData, setNewUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: {
      _id: "",
      name: "",
    },
  });

  const [usersFromDb, setUsersFromDb] = useState([]);
  const [showTable, setShowTable] = useState(false);

  // edit user functionality things

  const [editUserData, setEditUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    id: '',
    company : '',
    role: {
      _id: "",
      name: "",
    },
  });
  const setEditUserDetails = (e) => {
   setSelectedRole(e?.role?.name)
    setEditUserData({
      firstName: e.firstName,
    lastName: e.lastName,
    email: e?.email,
    id: e?._id,
    company : e?.company,
   role : {
    _id : e?.role?._id,
    name: e?.role?.name
   }
    });
   
  };
  const getEditUserDetailsFromForm = (e, check) => {
    if (check === true) {
      const obj = JSON.parse(e.target.value);
     setSelectedRole(obj.name)
      editUserData["role"] = obj;
    } else {
      setEditUserData((prev) => {
        return { ...prev, [e.target.id]: e.target.value };
      });
    }
  };

  // API call to get All user listing Data from the server

  const userDataFromAPi = () => {
    setIsFetching(true)
    apiCall("post", getAllUserData, dataForUserDBCall, true)
      .then((res) => {
        if (res.status == 200) {
          setUsersFromDb(res?.data?.data?.docs);
          setTotalDataLenght(res?.data?.totalUsers);
          setShowTable(true);
          setIsFetching(false)
        } else {
          setShowTable(false);
          setIsFetching(false)
        }
      
      })
      .catch((err) => {
        setShowTable(false);
        console.log(err);
      });
  };
  useEffect(() => {
    userDataFromAPi();
  }, [reRendListingData, currentPage]);
  const [rendControl, setRendControl] = useState(false);
  const [confirmationText, setConfimationText] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setConfimationText("");
    }, 3000);
  }, [rendControl]);

  const [selectedRole, setSelectedRole] = useState('')
  const newUserDataAdder = (e, check) => {
    
    if (check === "role") {
      const obj = JSON.parse(e.target.value);
      newUserData["role"] = obj;
      setSelectedRole(obj?.name)
    }
    
    else if(check== "network"){
      const obj = JSON.parse(e.target.value);
      newUserData["network_id"] = obj._id;
    }
    else {
      setNewUserData((prev) => {
        return { ...prev, [e.target.id]: e.target.value };
      })
    }
    console.log(newUserData)
  }

  function hideFunc() {
    var modal = document.querySelector("#exampleModalCenter");
    var backmodal = document.querySelector("div.modal-backdrop.fade.show");
    backmodal.style.display = "none";
    modal.style.display = "none";
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setIsFetching(true)
    if(!newUserData?.network_id || newUserData?.network_id == '' ){
      showToastMessage('Network is required' , 'red' , 2)
      setIsFetching(false)
      return null
    }
    apiCall("post", addUser, newUserData, true)
      .then((result) => {
        if (result.status == 200) {
          showToastMessage("User Added Successfully !! ", "green", 1);
          setReRendListingData(!reRendListingData);
          const button = document.getElementById("closeModalButton");
          button.click();
          setIsFetching(false)
          const element = document.getElementById("form1");
          element?.reset();
        }
      })
      .catch((err) => {
        var error;
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsFetching(false)
        try {
          setConfimationText(err?.response?.data?.message);
          setRendControl(!rendControl);
        } catch (err) {
          setConfimationText("Internal Server error. Please try again later");
          setRendControl(!rendControl);
        }
      });
  };

  // API call to get All user listing Data from the server

  // edit user functionality

  const [editConfirmationResponse, setEditConfirmationResponse] = useState("");
  const [editTextRender, setEditTextRender] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setEditConfirmationResponse("");
    }, 2500);
  }, [editTextRender]);

  const editUserSubmitHandler = (e) => {
    e.preventDefault();
    setIsFetching(true)
    apiCall("post", editUser, editUserData, true)
      .then((res) => {
        if (res.status == 200) {
          setReRendListingData(!reRendListingData);
          showToastMessage("User details updated Successfully", "green", 1);
          const button = document.getElementById("editactionModalClose");
          button.click();
          setIsFetching(false)
        }
        else{
          setIsFetching(false)
        }
      })

      .catch((err) => {
        console.log(err);
        setIsFetching(false)
        try {
          showToastMessage(err?.response?.data?.message, "red", 2);
        } catch (err) {
          showToastMessage(
            "Internal Server Error. Please try again Later",
            "red",
            2
          );
        }
      });
  };

  const deleteEntry = (e) => {
    setIsFetching(true)
    apiCall("post", deleteUser, { id: idToDelete }, true)
      .then((res) => {
        if (res.status == 200) {
          setReRendListingData(!reRendListingData);
          showToastMessage("User Deleted Successfully ", "green", 1);
          document.getElementById('closeModalButtonDelete').click()
          setIsFetching(false)
        }
      })
      .catch((err) => {
        showToastMessage(err?.response?.data.message, "red", 2);
        console.log(err);
        setIsFetching(false)
      });
  };

  function searchFromTable(e) {
    var input, filter, table, tr, td, i, txtValue;
    input = e.target.value;
    filter = input.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[2];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }
const [selectedId, setSelectedId] = useState()
  function modalInputEmpty(e) {
    
    var myModal = document.getElementById("change");
    
    myModal.addEventListener("hide.bs.modal", function (event) {
      document.getElementById("password1").value = "";
      document.getElementById("confirmPassword").value = "";
    });
  }

  function modalAddFieldsEmpty() {
    var myModal = document.getElementById("exampleModalCenter");
    myModal.addEventListener("hide.bs.modal", function (event) {
      document.getElementsByName("addUserField").forEach((ele, index) => {
        ele.value = "";
      });
    });
  }

  const [network, setNetwork] = useState([])
  const userDataFromAPiNetwork = () => {
    setIsFetching(true)
    apiCall("get", getAllNetworks, {}, true)
      .then((res) => {
        if (res.status == 200) {
          setNetwork(res?.data?.networks);
        } else {
          setNetwork([]);
        }
      
      })
      .catch((err) => {
        setShowTable(false);
        console.log(err);
      });
  };
  useEffect(() => {
    userDataFromAPiNetwork();
  }, []);

  return (
    <div id="page-top">
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
      />
      
      <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete an Entry
                </h5>
                <button
                  className="close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"

                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure ?</div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  Cancel
                </button>
                <a className="btn btn-primary d-flex flex-wrap" style={{display:'flex'}} onClick={deleteEntry}>
                  Delete <i style={isLoading? {display:"block"} : {display: 'none'}} className="fa fa-spinner fa-spin m-1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      <div
        className="modal fade"
        id="editmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editmodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Action
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="editactionModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12">
                    <div className="p-2">
                      <form
                        className="user"
                        onSubmit={editUserSubmitHandler}
                        id="editUser"
                      >
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="firstName"
                              placeholder="First Name"
                              value={editUserData.firstName}
                              onChange={getEditUserDetailsFromForm}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="lastName"
                              value={editUserData.lastName}
                              placeholder="Last Name"
                              onChange={getEditUserDetailsFromForm}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                       
                          <label htmlFor="exampleInputEmail1" readOnly>
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={editUserData.email}
                            placeholder="Email Address"
                            onChange={getEditUserDetailsFromForm}
                            aria-label="Disabled input example"
                            disabled
                          />
                        </div>
                        <div className="col-sm-6 mb-3 mb-sm-0">
                       
                          <label htmlFor="exampleInputEmail1" readOnly>
                            Company
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="company"
                            value={editUserData.company}
                            placeholder="company"
                            onChange={getEditUserDetailsFromForm}
                            disabled={selectedRole=="Admin" ? true : false}
                          />
                        </div>
                        </div>
                        <label htmlFor="exampleInputEmail1">Role</label>
                        <select
                          onChange={(e) => {
                            getEditUserDetailsFromForm(e, true);
                          }}
                          id="role"
                          className="form-control form-select"
                          placeholder="Role"
                          
                        
                        >

                          {userRoles?.map((element, index) => {
                           const checks =  (editUserData?.role?.name == element?.name) ? true: false
                          
                            return (
                              <option selected={checks} value={JSON.stringify(element)}>
                                {element?.name}
                              </option>
                            );
                          })}
                        </select>

                        <button className="btn btn-primary mt-3 d-flex flex-wrap float-end" type="submit">
                          Update
                          <i
                                      style={
                                        isFetching
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                        </button>
                        <p style={{ textAlign: "center" }} className="mt-3">
                          {editConfirmationResponse}
                        </p>
                      </form>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="change"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeChangePasswordModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12">
                    <div className="p-5">
                      <form
                        className="user"
                        onSubmit={changePasswordFormSubmission}
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1" readOnly>
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-user outline-none shadow-none"
                            id="email"
                            value={editUserData.email}
                            disabled
                            readOnly
                          />
                        </div>
                        <div className="form-group row">
                          <div className="col-12">
                            <label htmlFor="exampleInputEmail1">Password</label>
                            <input
                              type="password"
                              className="form-control form-control-user"
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                              id="password1"
                              onChange={changePasswordFunction}
                              placeholder="New password"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-12">
                            <label htmlFor="exampleInputEmail1">
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-user"
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                              id="confirmPassword"
                              placeholder="Confirm Password"
                              required
                            />
                          </div>
                          <div className="form-group row mt-4 d-flex flex-nowrap " style={(editUserData?.role?.name == "Admin") ? {display:'block'} : {display:'none'} }>
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            
                            <input
                              type='number'
                              className="form-control form-control-user"
                              id="otp"
                              placeholder="Enter OTP Code"
                              onChange={(e)=>{
                                setCode(e.target.value)
                              }}
                              
                            />
                          </div>

                            <p className="mt-1" onClick={()=>{sendOTP(editUserData?.email)}}>Send otp</p>
                          
                        </div>
                        <div className="col-sm-12 mb-3 mb-sm-0" >
                        <button
                                className="btn btn-primary mt-4 float-end  d-flex flex-wrap"
                                type="submit"
                              >
                            Submit
                            <i
                                      style={
                                        isFetching
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                          </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      <div id="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <h1
                className="h3 mb-0 text-gray-800 first-heading"
                id="usermanagement-h1"
              >
                User Management
              </h1>
            </div>
                                    
            <div className="col-4">
              <div className="search d-flex">
               
                <input
                  id="usermanagent-formField"
                  type="text"
                  onChange={(e) => {
                    setDataForUserDBCall((prev) => {
                      return { ...prev, ["search"]: e.target.value };
                    });
                  }}
                  className="form-control"
                  placeholder="Search Email/Role/Company"
                />
                <button
                  className="btn btn-primary w65"
                  onClick={() => {
                    setCurrentPage(1);
                    setReRendListingData(!reRendListingData);
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>

            </div>

            <div className="col-2" style={{ textAlign: "end" }}>
              <button
                type="button"
                className="btn btn-primary"
                id="usermanagementbtn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenter"
                onClick={modalAddFieldsEmpty}
              >
                <i className="fa fa-plus mr-2"></i>Add
              </button>
            </div>
          </div>

          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div
              className="modal fade"
              id="exampleModalCenter"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Add A New User
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="closeModalButton"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-12">
                          <div className="p-2">
                            <form
                              className="user"
                              id="addNewUser"
                              onSubmit={submitHandler}
                            >
                              <div className="form-group row">
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                  <label htmlFor="exampleInputEmail1">First Name</label>
                                  <input
                                    onChange={newUserDataAdder}
                                    type="text"
                                    className="form-control form-control-user"
                                    id="firstName"
                                    name="addUserField"
                                    placeholder="First Name"
                                    required
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <label htmlFor="exampleInputEmail1">Last Name</label>
                                  <input
                                    onChange={newUserDataAdder}
                                    type="text"
                                    className="form-control form-control-user"
                                    id="lastName"
                                    name="addUserField"
                                    placeholder="Last Name"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <label htmlFor="exampleInputEmail1">
                                  Email
                                </label>
                                <input
                                  onChange={newUserDataAdder}
                                  type="email"
                                  className="form-control form-control-user"
                                  id="email"
                                  name="addUserField"
                                  placeholder="Email Address"
                                  required
                                />
                              </div>
                                    
                            <div className="col-sm-6 mb-3 mb-sm-0" >
                              <label htmlFor="exampleInputEmail1">
                                  Company
                                </label>
                                <input
                                  onChange={newUserDataAdder}
                                  type="text"
                                  className="form-control form-control-user"
                                  id="company"
                                  name="addUserField"
                                  placeholder="Company name"
                                  disabled={selectedRole == "Admin" ? true : false}
                                />
                              </div>
                              </div>

                              <label htmlFor="exampleInputEmail1">Role</label>
                              <select
                                onChange={(e) => {
                                  newUserDataAdder(e, "role");
                                }}
                                id="role"
                                className="form-control form-select"
                                required
                              >
                                <option disabled selected value>
                                  Select your Role
                                </option>

                                {userRoles?.map((element) => {
                                  return (
                                    <option value={JSON.stringify(element)}>
                                      {element.name}{" "}
                                    </option>
                                  );
                                })}
                              </select>

                            <label className="pt-2" htmlFor="exampleInputEmail1">Network</label>
                              <select
                                onChange={(e) => {
                                  newUserDataAdder(e, "network");
                                }}
                                id="network"
                                className="form-control form-select"
                                required
                              >

                                <option disabled selected value>
                                  Select your Network
                                </option>

                                {network?.map((element) => {
                                  return (
                                    <option value={JSON.stringify(element)}>
                                      {element.name}{" "}
                                    </option>
                                  );
                                })}
                              </select>

                              <button
                                className="btn btn-primary mt-4 float-end  d-flex flex-wrap "
                                type="submit"
                              >
                                Submit
                                <i
                                      style={
                                        isFetching
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>
          </div>
          {showTable ? (
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary"></h6>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Company</th>
                        <th>Network</th>
                        <th>Edit Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {isFetching ? <tr><td colSpan='12'> <TableLoader /></td></tr> : 
                     usersFromDb?.length ? usersFromDb.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td>{ele.firstName ? ele?.firstName : "Admin"}</td>
                            <td>{ele?.lastName ? ele?.lastName : "Admin"}</td>
                            <td>{ele?.email}</td>
                            <td>{ele?.role?.name}</td>
                            
                            <td>{ele?.company ? ele?.company : '-'} </td>
                            <td>{ele?.network?.name || 'N/A'}</td>
                            <td
                              onClick={() => {
                                setEditUserDetails(ele);
                              
                              }}
                            >
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#editmodal"
                              >
                                <i className="fa fa-edit" />
                              </a>
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                              >
                                <i
                                  className="fa fa-trash-o m-1"
                                  id={ele?._id}
                                  onClick={(e) => {
                                   setIdToDelete(ele?._id);
                                  }}
                                />
                              </a>
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#change"
                                onClick={()=>{
                                  modalInputEmpty(ele?._id)
                                  setSelectedId(ele?._id)
                                }
                                }
                              >
                                <i className="fa fa-lock m-1" />
                              </a>
                            </td>
                          </tr>
                        )
                      })
                    : 
                    <tr>
                    <td colSpan="10">No Record Found</td>
                  </tr>
                    }
                    </tbody>
                  </table>
                  <div className=" paginate">
                    <ReactPaginate
                      onPageChange={paginate}
                      pageCount={Math.ceil(totalDataLenght / postsPerPage)}
                      previousLabel={"<"}
                      nextLabel={">"}
                      containerClassName={"pagination"}
                      pageLinkClassName={"page-number"}
                      previousLinkClassName={"page-number"}
                      nextLinkClassName={"page-number"}
                      activeLinkClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>No user exists in the Database. </p>
          )}
          <nav
            aria-label="Page navigation example"
            className="float-right"
          ></nav>
        </div>
      </div>
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
}
export default UserManagementComponent;
