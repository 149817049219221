export const environment = "https://backend.foodprochain.com/";
export const local = "http:/localhost:8000/";

// user APIs ROutes
export const adminLogin = environment + "users/login-user";
export const addUser = environment + "users/add-user";
export const loginUser = environment + "users/login-user";
export const editUser = environment + "users/edit-user";
export const getDbData = environment + "fabcar/get-dbdata";
export const changeUserPassword = environment + "users/change-password";
export const getContractData = environment + "fabcar/get-contractdata";
export const forgotPassword = environment + "users/forgot-password";
export const resetPassword = environment + "users/reset-password";
export const deleteUser = environment + "users/delete-user";
export const resendOtp = environment + "users/resend-otp";
export const getAllUserData = environment + "users/get-dbdata";
export const changeAdminPassword = environment + "users/change-pass-self";
export const generateOTPforPasswordChange = environment + "users/generate-otp";
export const getUserRoles = environment + "roles/get";

// recieving at packing API Routes

export const createItemPacking =  environment + "receiving-at-packing/create-item";
export const UpdateItemPacking =  environment + "receiving-at-packing/update-item";
export const deleteItemPacking =  environment + "receiving-at-packing/delete-item";
export const queryItemPacking = environment + "receiving-at-packing/query-item";
export const queryByPiecePacking =  environment + "receiving-at-packing/query-item-peace";
export const queryByQuantityPacking =  environment + "receiving-at-packing/query-item-quantity";
export const queryByDatePacking =  environment + "receiving-at-packing/query-item-date";
export const getAllItemsPacking =  environment + "receiving-at-packing/get-all-items";
export const getAllRecievingCode =  environment + "receiving-at-packing/get-all-ids";
export const getAllSuppliers = environment + 'receiving-at-packing/get-all-suppliers'

// Loading At Packing API Routes

export const loadItemLoadAtPacking =  environment + "loading-at-packing/load-item";
export const updateItemLoadAtPacking =  environment + "loading-at-packing/update-item";
export const deleteItemLoadAtPacking =  environment + "loading-at-packing/delete-item";
export const queryItemLoadAtPacking =  environment + "loading-at-packing/query-item";
export const getAllItemLoadAtPacking =  environment + "loading-at-packing/get-all-items";
export const getAllLoadingMaterialAtPacking=  environment +"loading-at-packing/get-all-loading-codes"

// Upload Files , delete File, get File

export const uploadAFile = environment + "file/upload-file";
export const deleteAFile = environment + "file/delete-file";
export const getAfile = environment + "file/get-file";

// traceAPI

export const traceProduct = environment + "trace";

// importer

export const getAllImporter = environment + "importer/get-all-items";
export const createImporter = environment + "importer/create-item";
export const updateItemImporter = environment + "importer/update-item";
export const querryItemImporter = environment + "importer/query-item";
export const deleteItemImporter = environment + "importer/delete-item";

// Exporter

export const createItemExporter = environment + "exporter/create-item";
export const updateItemExporter = environment + "exporter/update-item";
export const getAllItemExporter = environment + "exporter/get-all-items";
export const queryItemExporter = environment + "exporter/query-item";
export const deleteItemExporter = environment + "exporter/delete-item";
export const allExporterIds = environment + "exporter/get-all-shipment-ids";

//loading at manufacturer

export const getAllLoadingAtManufacturerCode =  environment + "loading-at-manufacturer/get-all-loading-codes";
export const getAllLoadingAtManufacturer =  environment + "loading-at-manufacturer/get-all-items";
export const createLoadingAtManufacturer =  environment + "loading-at-manufacturer/create-item";
export const updateLoadingAtManufacturer =  environment + "loading-at-manufacturer/update-item";
export const deleteLoadingAtManufacturer =  environment + "loading-at-manufacturer/delete-item";
export const queryLoadingAtManufacturer =  environment + "loading-at-manufacturer/query-item";

// Manufacturer warehouse

export const getAllManufacturerWareHouse =  environment + "manufacturer-warehouse/get-all-items";
export const createManufacturerWareHouse =  environment + "manufacturer-warehouse/create-item";
export const updateManufacturerWareHouse =  environment + "manufacturer-warehouse/update-item";
export const searchManufacturerWareHouse =  environment + "manufacturer-warehouse/query-item";
export const deleteManufacturerWareHouse =  environment + "manufacturer-warehouse/delete-item";
export const getAllIdsManufacturerWarehouse =  environment + "manufacturer-warehouse/get-ids"
 
// Certificates 

export const getAllItemsPackingHouseCertificate = environment + "certificate/get-all-items-packinghouse"
export const getAllItemsManufacturerWarehouseCertificate = environment + "get-all-items-manufacturerwarehouse"
export const getAlltemsManufacturerPackingCertificate = environment + "get-all-items-manufacturingpacking"
export const createItemCertificate = environment+ "certificate/create-item"
export const updateItemCertificate = environment + "certificate/update-item"
export const deleteItemCertificate= environment + "certificate/delete-item"
export const querryItemCertificate = environment + "certificate/query-item"
export const getAllCertificateFromDb = environment + "certificate/get-all-items"
export const getReceivingAtPackingHouseCertificate = environment +"certificate/get-all-items-packinghouse"
export const getManufacturingWarehouseCertificate = environment + 'certificate/get-all-items-manufacturerwarehouse'
export const getManfacturingAndPackingCertificate = environment + 'certificate/get-all-items-manufacturingpacking'
export const getImporterCertificate = environment + 'certificate/get-all-items-importer'
export const getExporterCertificate = environment + 'certificate/get-all-items-exporter'

//query certificates apis

export const getAllCompanyNameList =  environment + 'users/get-company' //get
export const getAllRolesForCertificates = environment + 'roles/get'

// query network apis
export const getAllNetworks = environment + 'networks/get-all-networks'
export const createNetwork = environment + 'networks/create-network'
export const updateNetwork = environment + 'networks/update-network/'
export const deleteNetwork = environment + 'networks/delete-network/'
export const GetNetworkByID = environment + 'networks/get-network/'