import axios from "axios";
var token;
export const apiCall = (method, url, body, attachHeader) => {
  token = localStorage.getItem("tokenFoodPro");
  if (method === "get") {
    // attachTokenToHeader(url)
    if (attachHeader) {
      attachTokenToHeader(url);
      if (axios.defaults.headers.common["Authorization"]) {
        return axios.get(url, body);
      } else {
        attachTokenToHeader(url);
      }
    }
    return axios.get(url);
  } else if (method === "post") {
    if (attachHeader) {
      attachTokenToHeader(url);
      if (axios.defaults.headers.common["Authorization"]) {
        return axios.post(url, body);
      } else {
        attachTokenToHeader(url);
      }
    } else {
      return axios.post(url, body);
    }
  } else if (method === "put") {
    // attachTokenToHeader(url)
    return axios.put(url, body);
  } else {
    // attachTokenToHeader(url)
    return axios.delete(url);
  }
};
function attachTokenToHeader(url) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}