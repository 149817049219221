import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { apiCall } from "../../apicalls/apiCall";
import { resendOtp } from "../../apicalls/apiRoutes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyTimer({ email, expiryTimestamp }) {
  const [confirmationText, setConfirmationText] = useState("");
  const [rendControl, setRendControl] = useState(false)
 

  const showToastMessage = (text,color,notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
    else{
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
   
  }
  
        const { restart, seconds, isRunning } = useTimer({
            expiryTimestamp,
          });
          useEffect(()=>{
            setTimeout(() => {
               
                if(!rendControl){
                    const time = new Date();
                    time.setSeconds(time.getSeconds() + 58);
                    restart(time)
                }
                
            }, 2500);
                },[rendControl])
  const resendOTP = (e) => {

    apiCall("post", resendOtp, { email: email })
      .then((res) => {
        if(res.status == 200 ){
          showToastMessage("OTP sent Successfully", 'green' ,1)            
        }
      })
      .catch((err) => {
        const error =  (err.toJSON())
        if (error.status == 406){
          showToastMessage(err?.response?.data?.message, 'red', 2)
        }
      });
  };

  return (
    <div className="mb-2" style={{ textAlign: "center" }}>
      {isRunning ? (
        <>
          <p className="mb-0" style={{ fontSize: "12px" }}>
            Resend OTP in
          </p>
          <span>{seconds}</span>
        </>
      ) : (
        <>
          <span style={{ cursor: "pointer" , backgroundColor:'#8DC641' , padding: '6px 14px' ,borderRadius : '30px' , color:'white'}} onClick={resendOTP}>
            Resend OTP
          </span>
        
        </>
      )}
    </div>
  );
}

export default function Timer({ email }) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 58);
  return (
    <div>
      <ToastContainer 
       position="bottom-center"
       autoClose={2000}
       hideProgressBar={true}
       />
      <MyTimer email={email} expiryTimestamp={time} />
    </div>
  );
}