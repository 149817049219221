import {
  faDeleteLeft,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import {
  getAllManufacturerWareHouse,
  getAllLoadingMaterialAtPacking,
  createManufacturerWareHouse,
  updateManufacturerWareHouse,
  deleteManufacturerWareHouse,
  queryItemLoadAtPacking,
  uploadAFile,
  allExporterIds,
  getManufacturingWarehouseCertificate,
} from "../../../apicalls/apiRoutes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { apiCall } from "../../../apicalls/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { TableLoader } from "../../component Main/loader";
import { timeConversion } from "../../component Main/timeConversion";
import { useNavigate } from "react-router-dom";
import SearchAbleDropDown from "./functions/searchAbleDropdown";

function RecievingAtManufacturerHouse(params) {
  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [rendControl, setrendControl] = useState(false);
  const [data, setData] = useState([]);
  const [IdToDelete, setIdToDelete] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [totalDataLenght, setTotalDataLenght] = useState();
  const [addNewData, setAddNewData] = useState({
    receivingDate: "",
    timeofReceiving: "",
    raw_materail: "",
    loadingId: "",
    certificateofanalysis: {},
    quantity: 0,
  });
  const [editObject, setEditObj] = useState({
    receivingDate: "",
    timeofReceiving: "",
    raw_materail: "",
    certificateofanalysis: {},
    quantity: 0
  });
  const [dto, setDto] = useState({
    bookmark: "",
    limit: limit,
    page: 1,
    search: "",
  });
  const [objToShow, setObjToShow] = useState();
  const [searchParams, setSearchParams] = useState("");
  const [packingCode, setPackingCode] = useState([]);
  useEffect(() => {
    getListinData();
  }, [pageNo, rendControl]);

  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };
  const navigate = useNavigate();

  const getPackingCodes = () => {
    setIsFetching(true);
    apiCall("get", getAllLoadingMaterialAtPacking, {}, true)
      .then((res) => {
        res?.data?.data?.docs.map((ele) => {
          const data = { label: ele?.loadingMaterialCode, value: ele?._id };
          packingCode.push(data);
        });
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsFetching(false);
      });
  };
  useEffect(() => {
    getPackingCodes();
  }, []);

  const getListinData = () => {
    setIsFetching(true);
    dto.page = pageNo;
    apiCall("post", getAllManufacturerWareHouse, dto, true)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalDataLenght(res?.data?.totalAssets);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsFetching(false);
      });
  };

  const EditEntrySubmit = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(editObject.raw_materail)) {
      showToastMessage("Raw material Cannot Only contain numbers", "red", 2);
      setIsFetching(false);
      return 0;
    }
    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", updateManufacturerWareHouse, editObject, true)
      .then((res) => {
        showToastMessage(
          "Loading at manufacturers editted Successfully",
          "green",
          1
        );
        setrendControl(!rendControl);
        setIsFetching(false);
        e.target.reset();
        setIsLoading(false);
        document.getElementById("closeModalButtonEdit").click();
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const deleteFunctionality = (e) => {
    setIsLoading(true);
    setIsFetching(true);
    apiCall("post", deleteManufacturerWareHouse, IdToDelete, true)
      .then((res) => {
        showToastMessage("Loaded at packing removed Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonDelete").click();
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const valueAdder = (e, name) => {
    name
      ? setEditObj((prev) => {
          return { ...prev, [e.target.id]: e.target.value };
        })
      : setAddNewData((prev) => {
          return { ...prev, [e.target.id]: e.target.value };
        });
  };

  const selectStateHandler = (params) => {
    setAddNewData((prev) => {
      return { ...prev, loadingId: params.value };
    });
  };

  const checkboxFunctionality = (e) => {
    if (e.target.checked) {
      addNewData["certificateofanalysis"][e.target.id] = "Yes";
    } else {
      addNewData["certificateofanalysis"][e.target.id] = "No";
    }
  };

  const addEntrySubmitHandler = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(addNewData.raw_materail)) {
      showToastMessage("Raw material Cannot Only contain numbers", "red", 2);
      setIsFetching(false);
      return 0;
    }

    if (addNewData.loadingId == "") {
      showToastMessage("LoadingID cannot be empty", "red", 2);
      return 0;
    }
    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", createManufacturerWareHouse, addNewData, true)
      .then((res) => {
        showToastMessage(
          "An entry at recieving at manufacturer added Successfully",
          "green",
          1
        );

        setIsFetching(false);
        setIsLoading(false);
        document.getElementById("closemodal").click();
        setrendControl(!rendControl);
        const element = document.getElementById("form1");
        element?.reset();
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const selectValues = (ele) => {
    const formatedDate = timeConversion(ele?.receivingDate);
    const KeysCeificates = Object.keys(ele.certificateofanalysis);
    const allCertificatesofAnalysis = {};

    for (const keyCertificate of KeysCeificates) {
      const searchIndex = certificate.findIndex(
        (c) => c._id == keyCertificate
      );

      if (searchIndex != -1) {
        allCertificatesofAnalysis[keyCertificate] =
          ele.certificateofanalysis[keyCertificate]['file'];
      }
    }
    getDateThreshold(ele?.loadingId)
    setEditObj({
      id: ele?.id,
      receivingDate: formatedDate,
      timeofReceiving: ele?.timeofReceiving,
      raw_materail: ele?.raw_material,
      certificateofanalysis: allCertificatesofAnalysis,
      quantity: ele?.quantity,
    });

  };

  const paginate = ({ selected }) => {
    dto.page = selected;
    setPageNo(selected + 1);
  };

  const [divCount, setDivCount] = useState(1);
  const [certificate, setCertificate] = useState([]);
  const [thresholdDate, setThresholdDate] = useState()

  const contractFileAdder = (e) => {
    addNewData['certificateofanalysis'] = {}
    setIsLoading(true);
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    apiCall("post", uploadAFile, formData, true)
      .then((res) => {
        setIsLoading(false);
     
        addNewData["certificateofanalysis"][e.target.id] =
          res.data.data.Location;
        showToastMessage("File Uploaded Successfulyy", "green", 1);
        setIsFetching(false);

      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        console.log(err);
        setIsFetching(false);
      });
     
  };
  const contractFileEditor = (e, name) => {
    setIsLoading(true);
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    apiCall("post", uploadAFile, formData, true)
      .then((res) => {
        setIsLoading(false);

        editObject["certificateofanalysis"][e.target.id] =
          res.data.data.Location;
        showToastMessage("File Uploaded Successfulyy", "green", 1);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        console.log(err);
        setIsFetching(false);
      });
  };

  const getAllCertificates = async () => {
    apiCall("get", getManufacturingWarehouseCertificate, {}, true)
      .then((result) => {
        setCertificate(result.data.data.docs);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    getAllCertificates();
  }, []);

  const searchHandler = (e) => {
    setSearchParams(e.target.value);
    packingCode.map((ele) => {
      if (ele?.loadingMaterialCode.includes(e.target.value)) {
        setObjToShow((prev) => {
          return { ...prev, ele };
        });
      }
    });
  };

useEffect(()=>{

  addNewData?.loadingId !== '' && getDateThreshold(addNewData?.loadingId)
},[addNewData?.loadingId])

const getDateThreshold = (id) =>{
  apiCall('post' , queryItemLoadAtPacking, {
    "id": id
  } , true).then((result) => {
   const dataa =  new Date(result?.data?.data?.dateofPostHarvestTreatment)?.toISOString().split('T')[0]
    setThresholdDate(dataa)
  }).catch((err) => {
    console.log("🚀 ~ file: receivingAtManufacturer.js:324 ~ apiCall ~ err:", err)
  });
}

  return (
    <div style={{ minHeight: "75vh" }}>
      {/* // add new entry modal */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add New
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-bs-label="Close"
                id="closemodal"
                onClick={() => {
                  document.getElementById("form1").reset();
                  setAddNewData({});
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={addEntrySubmitHandler} id="form1">
                    <div className="form-group row">
                    <div className="col-sm-12 mt-3 mt-2 mb-3">
                        <label for="exampleInputEmail1">Loading Id</label>

                        <SearchAbleDropDown
                          setChange={selectStateHandler}
                          data={packingCode}
                        />
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label for="birthday">Receiving Date</label>
                        <input
                          type="date"
                          className="form-control form-control-user"
                          id="receivingDate"
                          min={thresholdDate}
                          onChange={(e) => {
                            valueAdder(e);
                          }}
                        />
                      </div>

                      <div className="col-sm-6 mb-2">
                        <label for="exampleInputEmail1 mb-5">
                          Time of Receiving
                        </label>
                        <input
                          type="time"
                          className="form-control form-control-user"
                          id="timeofReceiving"
                         
                          onChange={valueAdder}
                          step="1"
                        />
                      </div>

                      <div className="col-sm-6">
                        <label for="exampleInputEmail1">Raw material</label>
                        <input
                          type="text"
                          className="form-control form-control-user"
                          id="raw_materail"
                          placeholder="Raw Material"
                          onChange={valueAdder}
                        />
                      </div>

                      <div className="col-sm-6">
                        <label for="exampleInputEmail1">Quantity</label>
                        <input
                          type="number"
                          className="form-control form-control-user"
                          id="quantity"
                          min="1"
                          placeholder="Quantity"
                          onChange={valueAdder}
                        />
                      </div>

                     

                      <div className="col-sm-12 mt-3">
                        <label class="ml-2 mb-3">Certificate of Analysis</label>
                      </div>
                      {certificate?.map((ele) => {
                        return (
                          <div className="col-12 mb-3">
                            <div className="mb-1 choosefile">
                              <label>{ele?.name}</label>
                              <input
                                type="file"
                                id={ele?._id}
                                name={ele?.name}
                                onChange={(e) => {
                                  contractFileAdder(e);
                                }}
                                required={ele?.required}
                              />
                            </div>
                          </div>
                        );
                      })}

                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary   d-flex flex-wrap"
                      >
                        Submit
                        {isLoading && (
                          <i
                            style={
                              isLoading
                                ? { display: "block" }
                                : { display: "none" }
                            }
                            className="fa fa-spinner fa-spin m-1"
                          ></i>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit an entry modal */}
      <div
        className="modal fade"
        id="editModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenter">
                Edit Listing
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalButtonEdit"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="p-2">
                    <form onSubmit={EditEntrySubmit}>
                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label for="birthday">Receiving Date</label>
                          <input
                            type="date"
                            className="form-control"
                            id="receivingDate"
                            min={thresholdDate}
                            value={editObject?.receivingDate}
                            onChange={(e) => {
                              valueAdder(e, true);
                            }}
                          />
                        </div>

                        <div className="col-sm-6 mb-2">
                          <label for="exampleInputEmail1 mb-5">
                            Time of Receiving
                          </label>
                          <input
                            type="time"
                            className="form-control form-control-user"
                            id="timeofReceiving"
                            value={editObject?.timeofReceiving}
                            onChange={(e) => {
                              valueAdder(e, true);
                            }}
                            step="1"
                          />
                        </div>
                        <div className="col-sm-6">
                          <label for="exampleInputEmail1">Raw material</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="raw_materail"
                            placeholder="Raw Material"
                            value={editObject?.raw_materail}
                            onChange={(e) => {
                              valueAdder(e, true);
                            }}
                          />
                        </div>

                        <div className="col-sm-6">
                          <label for="exampleInputEmail1">Quantity</label>
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="quantity"
                            min="1"
                            placeholder="Quantity"
                            value={editObject?.quantity}
                            onChange={(e) => {
                              valueAdder(e, true);
                            }}
                          />
                        </div>

                        <div className="col-sm-12 mt-3"></div>

                        <div className="col-sm-12 mt-3">
                          <label for="exampleInputEmail1">Loading Id</label>
                          <select
                            disabled
                            className="form-select"
                            onChange={valueAdder}
                            id="loadingId"
                          >
                            <option value={editObject?.loadingId}>
                              {editObject?.loadingId}
                            </option>
                          </select>
                        </div>
                        <div className="col-sm-12 mt-3">
                          <label class="ml-2 mb-3">
                            Certificate of Analysis
                          </label>
                        </div>
                        {certificate?.map((ele) => {
                          return (
                            <div className="col-12 mb-3">
                              <div className="mb-1 choosefile">
                                <label>{ele?.name}</label>
                                <input
                                  type="file"
                                  id={ele?._id}
                                  name={ele?.name}
                                  onChange={(e) => {
                                    contractFileEditor(e);
                                  }}
                                 
                                />
                              </div>
                           {
                           editObject['certificateofanalysis'][ele?._id] ? <a href={editObject['certificateofanalysis'][ele?._id]}> Show file </a> : ""

                           }   
                            </div>

                          );
                        })}
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary d-flex flex-wrap"
                        >
                          Update
                          {isLoading && (
                            <i className="fa fa-spinner fa-spin m-1"></i>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete an entry modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete an Entry
              </h5>
              <button
                className="close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalButtonDelete"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Are you sure ?</div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalButtonDelete"
              >
                Cancel
              </button>
              <a
                className="btn btn-primary"
                style={{ display: "flex" }}
                onClick={deleteFunctionality}
              >
                Delete
                <i
                  style={isLoading ? { display: "block" } : { display: "none" }}
                  className="fa fa-spinner fa-spin m-1"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editmodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Action
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-bs-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12">
                    <div className="p-2">
                      <form className="user">
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label htmlFor="exampleInputEmail1">Name</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="exampleFirstName"
                              placeholder="First Name"
                            />
                          </div>
                          <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1">Name</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="exampleLastName"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Email</label>
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="exampleInputEmail2"
                            placeholder="Email Address"
                          />
                        </div>
                        <label htmlFor="exampleInputEmail1">Role</label>
                        <select className="form-control">
                          <option>Select your Role</option>
                          <option>Packing House</option>
                          <option>Manufacturer</option>
                          <option>Distributor</option>
                          <option>Importer</option>
                        </select>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Discard
              </button>
              <button type="button" className="btn btn-primary">
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteEntry"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete an Entry
              </h5>
              <button
                className="close"
                type="button"
                data-bs-dismiss="modal"
                aria-bs-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Are you sure ?</div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <a className="btn btn-primary" divef="index.html">
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex flex-column">
          <div id="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <h1
                    className="h3 mb-0 text-gray-800 first-heading"
                    id="usermanagement-h1"
                  >
                    Manufacturing Warehouse
                  </h1>
                </div>
                <div className="col-sm">
                  {" "}
                  <div className="search d-flex butonn">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Loading Material Code	"
                      onChange={(e) => {
                        setDto((prev) => {
                          return { ...prev, ["search"]: e.target.value };
                        });
                      }}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setrendControl(!rendControl);
                        setPageNo(1);
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <div className="col-2" style={{ textAlign: "end" }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalCenter"
                  >
                    <i className="fa fa-solid fa-plus mr-2"></i>Add
                  </button>
                </div>

                <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"></h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th rowSpan="2" className="nowrap">
                              Raw Material
                            </th>
                            <th rowSpan="2">
                              Loading Material Code At Packing House
                            </th>
                            <th rowSpan="2">Quantity</th>
                            <th rowSpan="2">Receiving Date</th>
                            <th rowSpan="2">Time of Receiving</th>

                            <th rowSpan="2">Raw Material Receive Code</th>
                            <th rowSpan="2">Certificate of Analysis</th>
                            <th rowSpan="2" className="nowrap">
                              Edit Actions
                            </th>
                          </tr>
                          {/* <tr>
                            <th>Afatoxin</th>
                            <th>Bacteria</th>
                            <th>Pesticide</th>
                            <th className="nowrap">Yeast & Mold</th>
                            <th>HeavyMetal</th>
                          </tr> */}
                        </thead>
                        {isFetching ? (
                          <tr>
                            <td colSpan="12">
                              {" "}
                              <TableLoader />
                            </td>
                          </tr>
                        ) : data?.length !== 0 ? (
                          <tbody>
                            {data.map((element, index) => {
                              return (
                                <tr key={index}>
                                  <td>{element?.raw_material}</td>
                                  <td>
                                    {element?.loadingMaterialCodeAtPackinghouse}
                                  </td>
                                  <td>{element?.quantity}</td>
                                  <td>{element?.receivingDate}</td>
                                  <td>{element?.timeofReceiving}</td>
                                  <td>{element?.rawmaterial_receive_code}</td>
                                  <td>
                                    {certificate.length !== 0
                                      ? certificate?.map((ele) => {
                                          return element[
                                            "certificateofanalysis"
                                          ][ele?._id] ? (
                                            <>
                                              <p>{ele?.name}</p>
                                              <a
                                                href={
                                                  element[
                                                    "certificateofanalysis"
                                                  ][ele?._id]['file']
                                                }
                                              >
                                                Show File
                                              </a>
                                              |
                                            </>
                                          ) : (
                                            <p>-</p>
                                          );
                                        })
                                      : "-"}
                                  </td>

                                  <td>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModalCenter"
                                    >
                                      <i
                                        className="fa fa-edit"
                                        id={element._id}
                                        onClick={() => {
                                          selectValues(element);
                                        }}
                                      />
                                    </a>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      <i
                                        className="fa fa-trash-o m-1"
                                        id={element.id}
                                        onClick={(e) => {
                                          setIdToDelete({
                                            id: e.target.id,
                                          });
                                        }}
                                      />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tr>
                            <td colSpan="12">No Record Found</td>
                          </tr>
                        )}
                      </table>
                      <ReactPaginate
                        onPageChange={paginate}
                        pageCount={Math.ceil(totalDataLenght / limit)}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination"}
                        pageLinkClassName={"page-number"}
                        previousLinkClassName={"page-number"}
                        nextLinkClassName={"page-number"}
                        activeLinkClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
                <nav
                  aria-label="Page navigation example"
                  className="float-right"
                ></nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RecievingAtManufacturerHouse;
