import DashboardSidebar from "./components/sidebar";
import DashboardMainComponent from "./components/dashboardMainComponent";
import HeaderDashboard from "./components/header";
import Footer from "./components/footer";
import { useEffect } from "react";
import { useState } from "react";
import RecievingAtManufacturerHouse from "./components/receivingAtManufacturer";
import ReceivingAtPackingHouse from "./components/recievingAtPackingHouse";
import UserManagementComponent from "./components/userManagementComponent";
import LoadingAtPackingHouse from "./components/LoadingAtPackingHouse";
import ProductTraceBility from "./components/productTraceBility";
import Importer from "./components/importer";
import Exporter from "./components/exporter";
import LoadingAtManufacturer from "./components/loadingAtManufacturer";
import CertificateManagement from "./components/certificateManagement";
import NetworkManagement from "./networkManagement";
function Dashboard(params) {
  const location = window.location.pathname;
  const [newlocation, setNewLocation] = useState("");
  setTimeout(() => {
     console.clear() 
  }, 1000);
  useEffect(() => {
    if (location) {
      let tmp = location.slice(location.lastIndexOf("/"), location.length);
      setNewLocation(tmp);
    }
  }, [location]);
  return (
    <div className="page-top">
      <div id="wrapper">
        <DashboardSidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <HeaderDashboard />
          {newlocation == "/dashboard" && <DashboardMainComponent />}
          {newlocation == "/usermanagement" && <UserManagementComponent />}
          {newlocation == "/networkmanagement" && <NetworkManagement />}
          {newlocation == "/certificatemanagement" && <CertificateManagement />}
          {newlocation == "/recieving-at-packing-house" && (
            <ReceivingAtPackingHouse />
          )}
          {newlocation == "/loading-at-packing-house" && (
            <LoadingAtPackingHouse />
          )}
          {newlocation == "/recieving-at-manufacturer" && (
            <RecievingAtManufacturerHouse />
          )}
          {newlocation == "/Product-traceability" && <ProductTraceBility />}
          {newlocation == "/importer" && <Importer />}
          {newlocation == "/exporter" && <Exporter />}
          {newlocation == "/loading-at-manufacturer" && <LoadingAtManufacturer />}
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
