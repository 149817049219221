import { Navigate, useRoutes } from "react-router-dom";
import Homepage from "./pages/homepage";
import RegisterUser from "./pages/registerUser";
import ForgotPassword from "./pages/forgotPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import UserManagement from "./pages/dashboard/userManagement";
import ReceivingAtPackingHouse from "./pages/dashboard/components/recievingAtPackingHouse";
import RecievingAtManufacturerHouse from "./pages/dashboard/components/receivingAtManufacturer";
import LoadingAtPackingHouse from "./pages/dashboard/components/LoadingAtPackingHouse";
import ResetPassword from "./pages/resetPassword";
import ProductTraceBility from "./pages/dashboard/components/productTraceBility";
import Importer from "./pages/dashboard/components/importer";
import Exporter from "./pages/dashboard/components/exporter";
import LoadingAtManufacturer from "./pages/dashboard/components/loadingAtManufacturer";
import CertificateManagement from "./pages/dashboard/components/certificateManagement";
import NetworkManagement from "./pages/dashboard/networkManagement";
export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "/register",
      element: <RegisterUser />,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword />,
    },
    {
      path: "/resetPassword/:email?",
      element: <ResetPassword />,
    },

    {
      path: "/dashboard",
      element: <Dashboard />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "Product-traceability", element: <ProductTraceBility /> },
        { path: "usermanagement", element: <UserManagement /> },
        { path: "networkmanagement", element: <NetworkManagement /> },
        {
          path: "recieving-at-packing-house",
          element: <ReceivingAtPackingHouse />,
        },
        {
          path: "certificatemanagement",
          element : <CertificateManagement />
        },
        {
          path: "loading-at-packing-house",
          element: <LoadingAtPackingHouse />,
        },
        {
          path: "recieving-at-manufacturer",
          element: <RecievingAtManufacturerHouse />,
        },
        { path: "importer", element: <Importer /> },
        { path: "exporter", element: <Exporter /> },
        {path : "loading-at-manufacturer" , element : <LoadingAtManufacturer />}
      ],
    },
  ]);

  return routes;
}
