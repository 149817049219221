import { useEffect, useState } from "react";
import userImg from "../../../img/undraw_profile.svg";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../../apicalls/apiCall";
import {
  changeAdminPassword,
  generateOTPforPasswordChange,
} from "../../../apicalls/apiRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoimage from "../../../img/undraw_profile.svg";
function HeaderDashboard() {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  useEffect(() => {
    if (userDetails == "" || userDetails == null || userDetails == undefined) {
      localStorage.removeItem("userDetails");
      localStorage.removeItem("tokenFoodPro");
      document.location.reload(true);
    }
  }, []);

  const name =
    userDetails?.firstName &&
    userDetails.firstName + " " + userDetails.lastName;

  const navigate = useNavigate();
  var token, decodedToken;
  useEffect(() => {
    token = localStorage.getItem("tokenFoodPro");
    if (!token) {
      navigate("/");
    } else {
      decodedToken = jwtDecode(token);
    }
  });
  const [dataToSend, setDataToSend] = useState({
    token: token,
    newPassword: "",
    oldPassword: "",
    code: "",
  });
  const logoutHandler = (e) => {
    localStorage.removeItem("tokenFoodPro");
    document.location.reload(true);
  };

  const changePasswordSubmitHandler = (e) => {
    e.preventDefault();
    const newpasswordadmin = document.querySelector(
      "#confirmpasswordadmin"
    ).value;

    if (newpasswordadmin != dataToSend.newPassword) {
      showToastMessage("Password do not match");
    } else {
      apiCall("post", changeAdminPassword, dataToSend, true)
        .then((response) => {
          if (response.status == 200) {
            showToastMessage("Password changed Successfully", "green", 1);
            document.querySelector("#confirmpasswordadmin").value = "";
            document.querySelector("#password").value = "";
            document.querySelector("#oldPassword").value = "";
            document.querySelector("#confirmpasswordadminotp").value = "";
            const button = document.getElementById(
              "profileSettingModalCloseButton"
            );
            button.click();
          }
        })
        .catch((err) => {
          showToastMessage(err?.response?.data.message, "red", 2);
        });
    }
  };

  function clearFields() {
    var modal = document.getElementById("profileModal");
    modal.addEventListener("hide.bs.modal", function (event) {
      document.getElementsByName("passwordChange").forEach((ele) => {
        ele.value = "";
      });
    });
  }

  const sendOTP = (e) => {
    apiCall("post", generateOTPforPasswordChange, {}, true)
      .then((res) => {
        if (res.status == 200) {
          showToastMessage("OTP sent successfully", "green", 1);
        } else {
          showToastMessage("Server Error. Please try again later", "red", 2);
        }
      })
      .catch((err) => {
        showToastMessage(err?.response?.data.message, "red", 2);
      });
  };
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
      />
      <div
        className="modal fade"
        id="profileModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Profile setting
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="profileSettingModalCloseButton"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="p-2">
                    <div className="text-center">
                      <img
                        src={logoimage}
                        className="mb-3"
                        style={{ width: "30%" }}
                      />
                      <h1 className="h4 text-gray-900 mb-5">Profile Setting</h1>
                    </div>

                    <form
                      className="user"
                      onSubmit={changePasswordSubmitHandler}
                    >
                      <div className="form-group row ">
                        <div className="col-sm-6 ">
                          <label htmlFor="exampleInputEmail1" readOnly>
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-user shadow-none outline-none"
                            id="exampleInputEmail"
                            placeholder="Email Address"
                            value={userDetails.email}
                            disabled
                            readOnly
                          />
                        </div>

                        <div className="col-sm-6 mb-3">
                          <label htmlFor="exampleInputEmail1">
                            Old Password
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-user"
                            id="oldPassword"
                            name="passwordChange"
                            minLength="8"
                            placeholder="Old Password"
                            onChange={(e) => {
                              dataToSend.oldPassword = e.target.value;
                            }}
                            required
                          />
                        </div>

                        <div className="col-sm-6 ">
                          <label htmlFor="exampleInputEmail1">
                            New Password
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-user"
                            id="password"
                            minLength="8"
                            name="passwordChange"
                            placeholder="New Password"
                            onChange={(e) => {
                              dataToSend.newPassword = e.target.value;
                            }}
                            required
                          />
                        </div>

                        <div className="col-sm-6 mb-4">
                          <label htmlFor="exampleInputEmail1">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="passwordChange"
                            className="form-control form-control-user"
                            id="confirmpasswordadmin"
                            minLength="8"
                            placeholder="Confirm Password"
                            required
                          />
                        </div>

                        <div className="row mb-3">
                          <div className="col-sm-6">
                            <input
                              type="password"
                              name="passwordChange"
                              className="form-control form-control-user"
                              id="confirmpasswordadminotp"
                              placeholder="Otp"
                              onChange={(e) => {
                                dataToSend.code = e.target.value;
                              }}
                              required
                            />
                          </div>

                          <div className="col-sm-6 ">
                            <a
                              className="btn btn-secondary btn2"
                              onClick={sendOTP}
                              type="submit"
                            >
                              Send OTP
                            </a>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary col-sm-12"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <a className="btn btn-secondary btn2" onClick={logoutHandler}>
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>

      <form className="form-inline">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>
      </form>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow pr-4">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              {name}
            </span>
            <img className="img-profile rounded-circle" src={userImg} />
          </a>

          <div
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="userDropdown"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#profileModal"
              onClick={clearFields}
            >
              <i className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </a>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#logoutModal"
            >
              <i className="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
export default HeaderDashboard;
