import { apiCall } from "../../../../apicalls/apiCall";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllCertificateFromDb,
  getUserRoles,
} from "../../../../apicalls/apiRoutes";
export async function getAllRoles() {
  try {
    const response = await apiCall("get", getUserRoles, {}, true);
    return response;
  } catch (err) {
    console.log(err);
    return 0;
  }
}


export async function getAllCertificates(dto) {
  try {
    const response = await apiCall("post", getAllCertificateFromDb, dto , true);
    console.log(response)
    return response
  } catch (Err) {
    console.log(
      "🚀 ~ file: certificateManagementFuctions.js:21 ~ getAllCertificates ~ Err:",
      Err
    );
    return 0
  }
}

export function showToastMessage(text, color, notify){
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };


