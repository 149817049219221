import "../../../css/sb-admin-2.min.css";
import { useEffect, useState } from "react";
import { apiCall } from "../../../apicalls/apiCall";
import {
  getAllItemsPacking,
  uploadAFile,
  deleteAFile,
  createItemPacking,
  deleteItemPacking,
  UpdateItemPacking,
  getReceivingAtPackingHouseCertificate,
  getAllItemsPackingHouseCertificate,
  getAllSuppliers,
} from "../../../apicalls/apiRoutes";
import ReactPaginate from "react-paginate";
import "./component.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableLoader } from "../../component Main/loader";
import { timeConversion } from "../../component Main/timeConversion";
import { getAllCertificatesOfPackingHouse } from "./functions/recevingAtPackingFunctions";
function ReceivingAtPackingHouse() {
  // declaring variables

  const [currentPage, setCurrentPage] = useState(1);
  var [postsPerPage, setPostPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDataLenght, setTotalDataLenght] = useState();
  const [selectedIdToDelete, setSelectedIdToDelete] = useState(null);
  const [searchParams, setSearchParams] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [dataForApiCall, setDataForApiCall] = useState({
    bookmark: "",
    limit: postsPerPage,
    page: currentPage,
    search: "",
  });

  const [allItems, setAllItems] = useState("");
  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  const [dto, setDto] = useState({
    receivingDate: "",
    timeofReceiving: "",
    supliername: "",
    regionofItem: "",
    quantityofItem: null,
    peacePerKg: null,
    color: "",
    certificateofanalysis:{
     
    },
    receivecode: "",
    raw_material: "",
  });

  const [editDto, setEditDto] = useState({
    id: "",
    receivingDate: "",
    timeofReceiving: "",
    supliername: "",
    regionofItem: "",
    quantityofItem: null,
    peacePerKg: null,
    color: "",
    // pesticideresidualcriteria: "No",
    // alfatoxinresidualcrieria: "No",
    certificateofanalysis:{
      pesticide : ""
    },
    receivecode: "",
    raw_material: "",
  });

  const [certificate, setCertificate] = useState([])
  const [supplierName, setSupplierName] = useState([])
  const [certCheck, setCertCheck] = useState(false)
useEffect(()=>{
getAllCertificates()
getReceivingCodes()

},[])


useEffect(()=>{
certificate.length == 0 ? setCertCheck(false) : setCertCheck(true)
},[certificate])

const getReceivingCodes = async() =>{
const result = await apiCall('get',getReceivingAtPackingHouseCertificate,{},true).then((result) => {
}).catch((err) => {
  console.log((err,"result err"));
});
}

const getAllCertificates  = async () =>{
  apiCall('get',getAllItemsPackingHouseCertificate, {} , true).then((result) => {
    setCertificate(result.data.data.docs)
  }).catch((err) => {
    console.log(err,'we')
  });
}

  // creating functions

  const getAllItemsCall = () => {
    setIsFetching(true);
    apiCall("post", getAllItemsPacking, dataForApiCall, true)
      .then((res) => {
        setAllItems(res.data);
        setTotalDataLenght(res?.data?.data?.docs.length);
        setIsFetching(false);
      })
      .catch((err) => {
        setAllItems(err);
        setIsFetching(false);
      });
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const dtoValueAdder = (e) => {
    if (
      e.target.id == "alfatoxinresidualcrieria" ||
      e.target.id == "pesticideresidualcriteria"
    ) {
      setDto((prev) => {
        return { ...prev, [e.target.id]: e.target.checked ? "Yes" : "No" };
      });
    } else {
      setDto((prev) => {
        return { ...prev, [e.target.id]: e.target.value };
      });
    }
  };

  const fileUploader = (e, obj) => {
    setIsLoading(true);
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    apiCall("post", uploadAFile, formData, true)
      .then((res) => {
        setIsLoading(false);
        if (obj) {
         e.target.id=="certificateofanalysis" ? editDto['certificateofanalysis'][e.target.name] = res.data.data.Location : editDto[e.target.id] = res.data.data.Location;
        } else {
          e.target.id == "certificateofanalysis" ? dto['certificateofanalysis'][e.target.name] = res.data.data.Location : dto[e.target.id] = res.data.data.Location
        }
        showToastMessage("File Uploaded Successfulyy", "green", 1);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        console.log(err);
        setIsFetching(false);
      });
  };

  const deleteAnEntryFunction = (id) => {
    setIsFetching(true);
    apiCall("post", deleteItemPacking, { id: id }, true)
      .then((res) => {
        showToastMessage(
          "Receiving at packing deleted Successfully !!",
          "green",
          1
        );
        document.getElementById("deleteModalCloseButton").click();
        setRendControl(!rendControl);
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setIsFetching(false);
        showToastMessage(err?.response?.data?.message, "red", 2);
      });
  };

  const dateCreator = (date) => {
    const newdate = date.toString();
    const stringDate = newdate.split("-");
    const day = stringDate[2];
    const month = stringDate[1];
    const years = stringDate[0]?.slice(2);
    const fulldate = day + "" + month + "" + years;
    return fulldate;
  };

  const timeSetter = (param) => {
    const date = param.toString();
    const hour = date.slice(0, 2);
    const min = date.slice(3, 5);
    const newdate = hour + "" + min;
    return newdate;
  };

  const getFirstLetters = (param) => {
    const upperparam = param.toUpperCase();
    var matches = upperparam.match(/\b(\w)/g);
    var acronym = matches.join("");
    return acronym;
  };

  const codeCreator = () => {
    const firstLetter = getFirstLetters(dto.supliername);
    const dateLetter = dateCreator(dto.receivingDate);
    const timeLetter = timeSetter(dto.timeofReceiving);
    const regionLetter = getFirstLetters(dto.regionofItem);
    const pieceLetter = dto.peacePerKg;
    // const colorLetter = getFirstLetters(dto.color);
    const AFPLetter = dto.pesticideresidualcriteria == "Yes" ? "P" : "";
    const AFALetter = dto.alfatoxinresidualcrieria == "Yes" ? "P" : "";
    dto.receivecode =
      firstLetter +
      dateLetter +
      timeLetter +
      regionLetter +
      pieceLetter +
      // colorLetter +
      AFPLetter +
      AFALetter;
  };


  const submitHandler = async (e) => {

    e.preventDefault();
    setIsFetching(true);
    const resp = await codeCreator();
  
    if (/^\d+$/.test(dto.supliername)) {
      showToastMessage("Supplier Name Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
    }
 else if(/^\d+$/.test(dto.regionofItem)){
  showToastMessage("Region of Item Cannot Only contain numbers", "red", 2);
    setIsFetching(false)
      return 0
 }

    apiCall("post", createItemPacking, dto, true)
      .then((res) => {
        
        showToastMessage(
          "New recieving at packing added Successfully",
          "green",
          1
        );
        setRendControl(!rendControl);
        document.getElementById("addNewCloseButtonModal").click();
        setIsFetching(false);
        const element = document.getElementById("form1");
        element?.reset();
      })
      .catch((err) => {
        console.log(err);
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsFetching(false);
      });
  };

  //deleting image from s3 bucket on modal close

  // var closebtn = document.querySelectorAll("#addNewCloseButton");
  // closebtn.forEach((ele) => {
  //   ele.addEventListener("click", () => {
  //     const element = document.getElementById("form1");
  //     element.reset();
  //     if (dto.AnalysisforAfatoxin == "" || dto.AnalysisforPesticide == "") {
  //     } else if (dto.AnalysisforAfatoxin != "") {
  //       var url = dto.AnalysisforAfatoxin.split("/");
  //       const key = url[url.length - 1];
  //       setIsFetching(true);
  //       apiCall("post", deleteAFile, { Key: key }, true)
  //         .then((res) => {
  //           console.log(key);
  //           console.log(res);
  //           dto.AnalysisforAfatoxin = "";
  //           setIsFetching(false);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setIsFetching(false);
  //         });
  //     } else {
  //       var url = dto.AnalysisforPesticide.split("/");
  //       const key = url[url.length - 1];
  //       console.log(key);
  //       setIsFetching(true);
  //       apiCall("post", deleteAFile, { Key: key }, true)
  //         .then((res) => {
  //           console.log(res);
  //           dto.AnalysisforPesticide = "";
  //           setIsFetching(false);
  //         })
  //         .then((err) => {
  //           setIsFetching(false);
  //           console.log(err);
  //         });
  //     }
  //   });
  // });

  const editFunctionality = (obj) => {
    const timeconverted = timeConversion(obj.dateofReceiving)


    const KeysCeificates = Object.keys(obj.certificateofanalysis);
    const allCertificatesofAnalysis = {}

    for(const keyCertificate of KeysCeificates){
      const searchIndex = certificate.findIndex((c) => c._id==keyCertificate);

      if(searchIndex!=-1){
        allCertificatesofAnalysis[keyCertificate] = obj.certificateofanalysis[keyCertificate]['file']
      }
    }

    setEditDto({
      id: obj.id,
      receivingDate: timeconverted,
      timeofReceiving: obj.time,
      supliername: obj.suplierName,
      regionofItem: obj.region,
      quantityofItem: obj.quantity,
      peacePerKg: obj.peace,
      color: obj.colorState,
      certificateofanalysis: allCertificatesofAnalysis,
      receivecode: obj.receivingMaterialCode,
      raw_material: obj?.raw_material,
    });

    
    
    // var checkboxfnction = document.getElementsByName("checkboxFunction");
    // obj.pesticideResidualCriteria == "Yes"
    //   ? (checkboxfnction[0].checked = true)
    //   : (checkboxfnction[0].checked = false);
    // obj.alfatoxinResidualCrieria == "Yes"
    //   ? (checkboxfnction[1].checked = true)
    //   : (checkboxfnction[1].checked = false);

  };

  const editDtoAdder = (e) => {
    if (
      e.target.id == "alfatoxinresidualcrieria" ||
      e.target.id == "pesticideresidualcriteria"
    ) {
      setDto((prev) => {
        return { ...prev, [e.target.id]: e.target.checked ? "Yes" : "No" };
      });
    } 
    else if(e.target.id == "certificateofAnalysis"){
      setDto((prev) => {
        return { ...prev, [e.target.id]: e.target.checked ? "Yes" : "No" };
      });
    }
    else {
      setEditDto((prev) => {
        return { ...prev, [e.target.id]: e.target.value };
      });
    }
  };

  // calling and controlling rendering of apicalls

  const [rendControl, setRendControl] = useState(false);
  useEffect(() => {
    getAllItemsCall();
  }, [currentPage, rendControl]);

  const editSubmitHandler = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(editDto.supliername)) {
      showToastMessage("Supplier Name Cannot Only contain numbers", "red", 2);
    
      return 0
    }
 else if(/^\d+$/.test(editDto.regionofItem)){
  showToastMessage("Region of Item Cannot Only contain numbers", "red", 2);
      return 0
 }
    setIsFetching(true);
    setIsLoading(true)
    apiCall("post", UpdateItemPacking, editDto, true)
      .then((res) => {
        showToastMessage(
          "Receiving at Packing Updated Successfully",
          "green",
          1
        );
        setRendControl(!rendControl);
       document.getElementById('editModalCloseButton').click()
        setIsFetching(false);
        setIsLoading(false)
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsFetching(false);
        setIsLoading(false)

      });
  };

  const searchFunctionality = () => {
    setCurrentPage(1);
    setRendControl(!rendControl);
  };
  // return html

const checkURL = (url) =>{
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

  return (
    <>
      {/* start */}
      <div
        className="modal fade"
        id="editmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editmodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Listing
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="editModalCloseButton"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12">
                    <div className="p-2">
                      <form className="user" onSubmit={editSubmitHandler}>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label htmlFor="birthday">Date</label>
                            <input
                              type="date"
                              className="form-control form-control-user"
                              id="receivingDate"
                              placeholder="Time"
                              value={editDto.receivingDate}
                              autoComplete="off"
                              onChange={(e) => {
                                editDtoAdder(e);
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6 mb-5 mb-sm-0">
                            <label htmlFor="birthday">Time</label>
                            <input
                              type="time"
                              className="form-control form-control-user"
                              id="timeofReceiving"
                              value={editDto.timeofReceiving}
                              placeholder="Time"
                              onChange={(e) => {
                                editDtoAdder(e);
                              }}
                              step="1"
                              required
                            />
                          </div>
                          <div className="col-sm-6 mb-2 mt-3">
                            <label htmlFor="exampleInputEmail1">
                              Supplier Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="supliername"
                              value={editDto.supliername}
                              placeholder="Supplier Name"
                              onChange={(e) => {
                                editDtoAdder(e);
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6 mb-2 mt-3">
                            <label htmlFor="exampleInputEmail1">
                              Region/Zone/District
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="regionofItem"
                              value={editDto.regionofItem}
                              placeholder="Region of Item"
                              onChange={(e) => {
                                editDtoAdder(e);
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6 mb-2">
                            <label htmlFor="exampleInputEmail1 mb-5">
                              Quantity (Kg)
                            </label>
                            <input
                              type="number"
                              className="form-control form-control-user"
                              id="quantityofItem"
                              value={editDto.quantityofItem}
                              min='1'
                              placeholder="Quantity of item"
                              onChange={(e) => {
                                editDtoAdder(e);
                              }}
                              required
                            />
                          </div>

                          <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1">Peach/Kg</label>
                            <input
                              type="number"
                              className="form-control form-control-user"
                              id="peacePerKg"
                              placeholder="Peach/Kg"
                              value={editDto.peacePerKg}
                              onChange={(e) => {
                                editDtoAdder(e);
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1">
                              Colour State
                            </label>
                          
                            <input
                              style={{display:'none'}}
                              type="file"
                              className="form-control form-control-user"
                              id="color"
                              placeholder="Colour State"
                              onChange={(e) => {
                                fileUploader(e, true);
                              }}
                              
                            />
                            <a target="_blank" href={editDto?.color}>Show File</a>
                          </div>
                          <div className="col-6">
                            <label htmlFor="exampleInputEmail1">
                              Raw Material
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="raw_material"
                              value={editDto.raw_material}
                              placeholder="Raw material"
                              onInput={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.match(/\d/)) {
                                  e.target.value = inputValue.replace(/\d/g, ''); // Remove numeric characters
                                } else {
                                  editDtoAdder(e);
                                }
                              }}
                              required
                            />
                          </div>
                        
                          <div className="col-12 mb-3 mt-3">
                            <label htmlFor="exampleInputEmail1">
                              Acredited Certifcate of Analysis for Pesticide
                            </label>
                           </div>
                           {
                            certificate?.map((ele)=>{
                              return(
                                <div className="col-12 mb-3">
                          <label>{ele?.name}</label>
                        
                            <div className="mb-1 choosefile d-flex">
                         
                              <input

                                type="file"
                                id="certificateofanalysis"
                                name={ele?._id}
                                onChange={(e) => {
                                  fileUploader(e, true);
                                }}
                              
                              />
                          {
                            editDto['certificateofanalysis'][ele._id] ? 
                         <a href={editDto['certificateofanalysis'][ele._id]}>View File</a> : ""
                        }   
                            </div>
                          
                        </div>
                              )
                            })
                           }
                         
                          <div className="modal-footer">
                          
                            <button type="submit" className="btn btn-primary   d-flex flex-wrap">
                          Update
                               <i
                               style={
                                 isLoading
                                   ? { display: "block" }
                                   : { display: "none" }
                               }
                               className="fa fa-spinner fa-spin m-1"
                             ></i>
                             
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="add2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add New
              </h5>
              <button
                type="button"
                className="close"
                id="addNewCloseButtonModal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12">
                    <form className="user" onSubmit={submitHandler} id="form1">
                      <div className="form-group row">
                        <div className="col-6  mb-3">
                          <label htmlFor="birthday">Date</label>
                          <input
                            type="date"
                            className="form-control form-control-user"
                            id="receivingDate"
                            placeholder="Time"
                            onChange={(e) => {
                              dtoValueAdder(e);
                            }}
                            required
                          />
                        </div>
                        <div className="col-6  mb-3">
                          <label htmlFor="birthday">Time</label>
                          <input
                            type="time"
                            className="form-control form-control-user"
                            id="timeofReceiving"
                            placeholder="Time"
                            onChange={(e) => {
                              dtoValueAdder(e);
                            }}
                            step="1"
                            required
                          />
                        </div>
                        <div className="col-6  mb-3">
                        <label htmlFor="exampleInputEmail1">
                              Supplier Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="supliername"
                              value={dto.supliername}
                              placeholder="Supplier Name"
                              onChange={(e) => {
                                dtoValueAdder(e);
                              }}
                              required
                            />
                       
                        </div>
                        <div className="col-6  mb-3">
                          <label htmlFor="exampleInputEmail1">
                            Region/Zone/District
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="regionofItem"
                            placeholder="Region of Item"
                            onChange={(e) => {
                              dtoValueAdder(e);
                            }}
                            required
                          />
                        </div>
                        <div className="col-6  mb-3">
                          <label htmlFor="exampleInputEmail1 mb-5">
                            Quantity (Kg)
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="quantityofItem"
                            placeholder="Quantity of item"
                            min='1'
                            onChange={(e) => {
                              dtoValueAdder(e);
                            }}
                            required
                          />
                        </div>

                        <div className="col-6  mb-3">
                          <label htmlFor="exampleInputEmail1">Peach/Kg</label>
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="peacePerKg"
                            placeholder="Peach/Kg"
                            onChange={(e) => {
                              dtoValueAdder(e);
                            }}
                            required
                          />
                        </div>
                        <div className="col-6  mb-3">
                          <label htmlFor="exampleInputEmail1">
                            Colour State
                          </label>
                          <input
                            type="file"
                            className="form-control form-control-user"
                            id="color"
                            placeholder="Colour State"
                            onChange={(e) => {
                              fileUploader(e,false);
                            }}
                            required
                          />
                        </div>
                        <div className="col-6  mb-3">
                          <label htmlFor="exampleInputEmail1">
                            Raw Material
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="raw_material"
                            placeholder="Raw material"
                            onInput={(e) => {
                              const inputValue = e.target.value;
                              if (inputValue.match(/\d/)) {
                                e.target.value = inputValue.replace(/\d/g, ''); // Remove numeric characters
                              } else {
                                dtoValueAdder(e);
                              }
                            }}
                            required
                          />
                        </div>
                        <div className="col-6  mb-3">
                          <label htmlFor="exampleInputEmail1"></label>
                         
                        </div>
                       
                        <label htmlFor="exampleInputEmail1">
                            Acredited Certifcate of Analysis for Pesticide
                          </label>
                        {
                      
                          certificate?.map((ele)=>{
                            return(
                            <div className="col-12 mb-3">
                          <label>{ele?.name}</label>
                            <div className="mb-1 choosefile">
                              <input
                                type="file"
                                id='certificateofanalysis'
                                name={ele?._id}
                                onChange={(e) => {
                                  fileUploader(e, false);
                                }}
                                required= {ele?.required}
                              />
                            </div>
                        </div>
                            )
                          })
                        }
                        
                       
                        <div className="modal-footer">
                       

                          <button type="submit" className="btn btn-primary   d-flex flex-wrap">
                            Submit
                             <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>

                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end */}

      <div id="page-top">
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <h1
                      className="h3 mb-0 text-gray-800 first-heading"
                      id="usermanagement-h1"
                    >
                      RM Receiving at Packing House
                    </h1>
                  </div>

                  <div class="col-md-4">
                    <div className="search d-flex butonn">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by supplier name"
                        onChange={(e) => {
                          setDataForApiCall((prev) => {
                            return { ...prev, ["search"]: e.target.value };
                          });
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={searchFunctionality}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-2 " style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="usermanagementbtn"
                      data-bs-toggle="modal"
                      data-bs-target="#add2"
                    >
                      <i className="fa fa-plus mr-2"></i>Add
                    </button>
                  </div>
                </div>

                <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"></h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive custom-table">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th rowSpan="2">Date/Time</th>
                            <th rowSpan="2">Suplier Name</th>
                            <th rowSpan="2">Region/Zone/District</th>
                            <th rowSpan="2">Quantity (kg)</th>
                            <th rowSpan="2">Raw material</th>
                            <th rowSpan="2">Peach/Kg</th>
                            <th rowSpan="2">Colour/State</th>
                            <th rowSpan="2">Product Certificate</th>
                            <th rowSpan="2">Company</th>
                            <th rowSpan="2">Receiving material code</th>
                            <th rowSpan="2" className="nowrap">Edit Actions</th>
                          </tr>
                        
                        </thead>
                        {isFetching ? (
                          <tr><td colSpan='12'> <TableLoader /></td></tr>
                        ) : allItems?.data?.docs?.length ? (
                          <tbody>
                            {allItems?.data?.docs.map((ele, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {ele?.dateofReceiving} / {ele?.time}
                                  </td>
                                  <td>{ele?.suplierName}</td>
                                  <td>{ele?.region}</td>
                                  <td>{ele?.quantity}</td>
                                  <td>{ele?.raw_material}</td>
                                  <td>{ele?.peace}</td>
                                  <td>
                                  {
                                      checkURL(ele.colorState) ? <a target="blank" href={ele.colorState} >View File</a> : "-"
                                    }
                                  </td>
                                  
                                  <td>
                                   {
                                    certificate?.map((element)=>{
                                      
                                      return(
                                      ele['certificateofanalysis'][element?._id] ? <><p> {element?.name}</p><a href={ele['certificateofanalysis'][element?._id]['file']}>Show File</a>  | </> : "-"
                                      )
                                    })
                                    
                                   }
                                  </td>
                                  <td>{ele?.company}</td>
                                  <td>{ele.receivingMaterialCode}</td>

                                  <td>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#editmodal"
                                    >
                                      <i
                                        className="fa fa-edit"
                                        onClick={() => {
                                          editFunctionality(ele);
                                        }}
                                      />
                                    </a>
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteEntry"
                                    >
                                      <i
                                        className="fa fa-trash-o m-1"
                                        id={ele.id}
                                        onClick={(e) => {
                                          setSelectedIdToDelete(e.target.id);
                                        }}
                                      />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tr>
                          <td colSpan="12">No Record Found</td>
                        </tr>
                        )}
                      </table>
                    </div>
                    <div className=" paginate">
                      <ReactPaginate
                        onPageChange={paginate}
                        pageCount={Math.ceil(totalDataLenght / postsPerPage)}
                        previousLabel={"<"}
                        nextLabel={">"}
                        containerClassName={"pagination"}
                        pageLinkClassName={"page-number"}
                        previousLinkClassName={"page-number"}
                        nextLinkClassName={"page-number"}
                        activeLinkClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" divef="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        <div
          className="modal fade"
          id="deleteEntry"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete an Entry
                </h5>
                <button
                  className="close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure ?</div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  id="deleteModalCloseButton"
                >
                  Cancel
                </button>
                <a
                  className="btn btn-primary d-flex- flex-wrap"
                  onClick={() => {
                    deleteAnEntryFunction(selectedIdToDelete);
                  }}
                >
                  Delete  <i style={isLoading? {display:"block"} : {display: 'none'}} className="fa fa-spinner fa-spin m-1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ReceivingAtPackingHouse;
