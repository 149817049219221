import box from "../../../img/box.png";

import one from "../../../img/1.png";
import two from "../../../img/5.png";
import three from "../../../img/2.png";
import four from "../../../img/4.png";
import six from "../../../img/6.png";
import siven from "../../../img/7.png";
import { apiCall } from "../../../apicalls/apiCall";
import { traceProduct } from "../../../apicalls/apiRoutes";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { ToastContainer, toast } from "react-toastify";
function ProductTraceBility(params) {
  const [response, setResponse] = useState();
  const [dto, setDto] = useState({});
  const [value] = useDebounce(dto, 1000);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const getTraceProduct = () => {
    if (dto?.id) {
      setIsLoading(true);
      apiCall("post", traceProduct, dto, false)
        .then((res) => {
          setResponse(res?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setResponse({});
          setIsLoading(false);
          showToastMessage(err?.response?.data?.message, "red", 2);
        });
    }
  };
  useEffect(() => {
    isSearched && getTraceProduct();
  }, [value]);

  useEffect(() => {
    dto?.id == "" && setDto({});
  }, [dto]);

  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  return (
    <>
      <div className="main-section">
        <div className="firs-section">
          <div className="container">
            <div className="row">
              <div className="col-sm"></div>
              <div className="col-sm"></div>
              <div className="col-sm">
                <div className="input-group">
                  <div className="search d-flex butonn">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      id="id"
                      onChange={(e) => {
                        setIsSearched(true);
                        setDto({
                          id: e.target.value,
                        });
                      }}
                    />
                    <button className="btn btn-primary">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="timeline">
          <li
            style={
              response?.receivingAtPacking
                ? {
                    filter: "grayscale(0%)",
                  }
                : { filter: "grayscale(100%)" }
            }
          >
            <div className="direction-l triangle-left">
              <div className="flag-wrapper ">
                <span className="flag">RM Receiving at Packing House</span>
                <span className="time-wrapper">
                  <span className="time"></span>
                </span>
              </div>

              <div className="logo">
                <img
                  className="img-profile"
                  src={one}
                  width="150"
                  height="150"
                />
              </div>

              {response?.receivingAtPacking?.map((element, index) => {
                return(
                <div className="flag-wrapper mt-3">
                  <span className="flag2 ">{element?.dateofReceiving}</span>
                  <span className="time-wrapper">
                    <span className="time">{element?.raw_material}</span>
                  </span>
                  <span className="time-wrapper">
                    <span className="time">{element?.receivingMaterialCode}</span>
                  </span>
                 
                </div>)
              })}

              <div className="desc"></div>
            </div>
          </li>

          <li
            style={
              response?.loadingAtPacking
                ? {
                    filter: "grayscale(0%)",
                  }
                : { filter: "grayscale(100%)" }
            }
          >
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">Operation of packing house</span>
                <span className="time-wrapper">
                  <span className="time"></span>
                </span>
              </div>

              <div className="warp">
                <div className="logo">
                  <img
                    className="img-profile"
                    src={four}
                    width="200"
                    height="120"
                  />
                </div>

                {response?.loadingAtPacking ? (
                  response?.loadingAtPacking?.map((element, index) => {
                    return (
                      <div className="flag-wrapper" key={index}>
                        <span className="flag2 ">Product {index + 1}</span>
                        <span className="time-wrapper">
                          <span className="time">
                            {element?.dateofPostHarvestTreatment || "N/A"}
                          </span>
                        </span>
                        <span className="time-wrapper">
                          <span className="time">
                            {element?.loadingMaterialCode || "N/A"}
                          </span>
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </div>
          </li>

          <li
            style={
              response?.manufacturerWarehouse
                ? {
                    filter: "grayscale(0%)",
                  }
                : { filter: "grayscale(100%)" }
            }
          >
            <div className="direction-l ">
              <div className="flag-wrapper">
                <span className="flag mb-4">Manufacturing Warehouse</span>
                <span className="time-wrapper">
                  <span className="time"></span>
                </span>
              </div>

              <div className="logo">
                <img
                  className="img-profile"
                  src={two}
                  width="200"
                  height="200"
                />
              </div>
              {response?.manufacturerWarehouse &&
                response?.manufacturerWarehouse.map((ele, index) => {
                  return (
                    <div className="flag-wrapper" key={index}>
                      <span className="flag2 ">{ele?.raw_material}</span>
                      <span className="time-wrapper">
                        <span className="time">{ele?.receivingDate}</span>
                      </span>
                      <span className="time-wrapper">
                        <span className="time">{ele?.rawmaterial_receive_code}</span>
                      </span>
                      

                    </div>
                  );
                })}
              <div className="desc">
                My current employment. Way better than the position before!
              </div>
            </div>
          </li>

          <li
            style={
              response?.loadingAtManufacturer
                ? {
                    filter: "grayscale(0%)",
                  }
                : { filter: "grayscale(100%)" }
            }
          >
            <div className="direction-r ">
              <div className="flag-wrapper">
                <span className="flag">Manufacturing and Packaging</span>
                <span className="time-wrapper">
                  <span className="time"></span>
                </span>
              </div>
              <div className="logo">
                <img
                  className="img-profile"
                  src={four}
                  width="200"
                  height="120"
                />
              </div>
              {response?.loadingAtManufacturer &&
                response?.loadingAtManufacturer.map((ele, index) => {
                  return (
                    <div className="flag-wrapper" key={index}>
                      <span className="flag2 ">{ele?.productcode}</span>
                      <span className="time-wrapper">
                        <span className="time">
                          {ele?.dateoffillingpacking}
                        </span>
                      </span>
                      <span className="time-wrapper">
                        <span className="time">
                          {ele?.loadingatManufacturerCode}
                        </span>
                      </span>
                    </div>
                  );
                })}
              <div className="desc">
                My first employer. All the stuff I've learned and projects I've
                been working on.
              </div>
            </div>
          </li>
          <li
            style={
              response?.exporter
                ? {
                    filter: "grayscale(0%)",
                  }
                : { filter: "grayscale(100%)" }
            }
          >
            <div className="direction-l">
              <div className="flag-wrapper">
                <span className="flag">Exporter</span>
                <span className="time-wrapper">
                  <span className="time"></span>
                </span>
              </div>
              <div className="logo">
                <img
                  className="img-profile"
                  src={six}
                  width="200"
                  height="120"
                />
              </div>
              {response?.exporter &&
                response?.exporter?.map((ele) => {
                  return (
                    <div className="flag-wrapper">
                      <span className="flag2 ">{ele?.origincountry}</span>
                      <span className="time-wrapper">
                        <span className="time">{ele?.shipmentDate}</span>
                      </span>
                      <span className="time-wrapper">
                        <span className="time">{ele?.shipmentID}</span>
                      </span>
                      
                    </div>
                  );
                })}
              <div className="desc">
                My first employer. All the stuff I've learned and projects I've
                been working on.
              </div>
            </div>
          </li>
          <li
            style={
              response?.importer
                ? {
                    filter: "grayscale(0%)",
                  }
                : { filter: "grayscale(100%)" }
            }
          >
            <div className="direction-r mb-5">
              <div className="flag-wrapper">
                <span className="flag">Importer</span>
                <span className="time-wrapper">
                  <span className="time"></span>
                </span>
              </div>

              <div className="logo">
                <img
                  className="img-profile"
                  src={six}
                  width="200"
                  height="200"
                />
              </div>
              {response?.importer &&
                response?.importer.map((ele) => {
                  return (
                    <div className="flag-wrapper">
                      <span className="flag2 ">{ele?.origincountry}</span>
                      <span className="time-wrapper">
                        <span className="time">{ele?.receivingDate}</span>
                      </span>
                      <span className="time-wrapper">
                        <span className="time">{ele?.receivingID}</span>
                      </span>
                    </div>
                  );
                })}
              <div className="desc">
                My current employment. Way better than the position before!
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
export default ProductTraceBility;
