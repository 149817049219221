import Select from "react-select";
import { apiCall } from "../../../../apicalls/apiCall";
import { getAllLoadingMaterialAtPacking } from "../../../../apicalls/apiRoutes";
import { useEffect, useState } from "react";

export default function SearchAbleDropDown(props) {
    const {setChange, data}  = props

  return (
    
    <div className="App">
      <Select options={data} onChange={(opt)=>{setChange(opt,"loadingId")}} />
    </div>
  );
}
