import { useEffect, useState } from "react";
import { apiCall } from "../../../apicalls/apiCall";
import {
  getAllItemExporter,
  updateItemExporter,
  deleteItemExporter,
  createItemExporter,
  queryItemExporter,
  getAllLoadingAtManufacturerCode,
  uploadAFile,
  getExporterCertificate,
  queryLoadingAtManufacturer,
} from "../../../apicalls/apiRoutes";
import "../../../css/sb-admin-2.min.css";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableLoader } from "../../component Main/loader";
import { timeConversion } from "../../component Main/timeConversion";
function Exporter(params) {
  const [currentPage, setCurrentPage] = useState(1);
  var [postsPerPage, setPostPerPage] = useState(10);
  const [totalDataLenght, setTotalDataLenght] = useState();
  const [currentRecords, setCurrentRecord] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [idArray, setIdArray] = useState([]);
  const [rendControl, setrendControl] = useState(false);
  const [dataForApiCall, setDataForApiCall] = useState({
    bookmark: "",
    limit: postsPerPage,
    page: currentPage,
    search: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [certificate, setCertificate] = useState([])
  const showToastMessage = (text, color, notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    } else {
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: { color: color },
      });
    }
  };

  const [IdToDelete, setIdToDelete] = useState({
    id: null,
  });
  const [Data, setData] = useState();
  const [dateThreshold, setThresholdDate] = useState()

  const [manufacturerCode, setManufacturerCode] = useState([]);
  const [addNewEntryData, setAddNewEntryData] = useState({
    receivingDate: "",
    shipmentDate: "",
    certificateofanalysis: {},
    originport: "",
    origincountry: "",
    destinationport: "",
    destinationcountry: "",
    loadingAtManufacturerID: "",
    shipmentTime:"",
    receivingTime:""
  });

  const [editEntryData, setEditEntryData] = useState({
    receivingDate: "",
    shipmentDate: "",
    certificateofanalysis: {
      Afatoxin: "",
      Pesticide: "",
      Bacteria: "",
    },
    originport: "",
    origincountry: "",
    destinationport: "",
    destinationcountry: "",
    loadingAtManufacturerID: "",
    shipmentTime:"",
    receivingTime:""
  });

  const getAllLoadingData = () => {
    setIsFetching(true);
    apiCall("post", getAllItemExporter, dataForApiCall, true)
      .then((result) => {
        setTotalDataLenght(result?.data?.data?.docs?.length);
        setData(result.data);
        setIsFetching(false);
      })
      .catch((err) => {
        setData(err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const AllLoadingAtManufacturerCode = () => {
    setIsFetching(true);
    apiCall("get", getAllLoadingAtManufacturerCode, {}, true)
      .then((res) => {
        setManufacturerCode(res?.data?.data?.docs);
        setIsFetching(false);
      })
      .catch((Err) => {
        console.log(Err);
        setIsFetching(false);
      });
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const storeValues = (e) => {
    setAddNewEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const storeIdsInObj = (e) => {
    const newObj = JSON.parse(e);
    addNewEntryData["receiveCode"] = newObj.receivingMaterialCode;
    addNewEntryData["materialId"] = newObj._id;
  };

  useEffect(()=>{
    apiCall('get',getExporterCertificate , {bookmark : "" , limit: 20  } , true).then((result) => {
      setCertificate(result.data.data.docs)
    }).catch((err) => {
      toast.warn('Internal Server Error.')
      console.log("🚀 ~ file: exporter.js:58 ~ apiCall ~ err:", err)
    });
       },[])
  
  const addNewEntry = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(addNewEntryData.originport)) {
      showToastMessage("Port Origin Cannot Only contain numbers", "red", 2);
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(addNewEntryData.origincountry)) {
      showToastMessage("Country Origin Cannot Only contain numbers", "red", 2);
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(addNewEntryData.destinationport)) {
      showToastMessage(
        "Destination Port Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(addNewEntryData.destinationcountry)) {
      showToastMessage(
        "Destination Country Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (addNewEntryData?.originport.toUpperCase() == addNewEntryData.destinationport?.toUpperCase()) {
      showToastMessage(
        "Origin Port and Destination Port cannot be same",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (addNewEntryData.receivingDate > addNewEntryData.shipmentDate) {
      showToastMessage(" Date of shipment cannot be before the date of receiving ");
      setIsFetching(false);
      return 0;
    }
    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", createItemExporter, addNewEntryData, true)
      .then((res) => {
        showToastMessage("Exporter Added successfully", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonAddNew").click();
        setIsFetching(false);
        const element = document.getElementById("form1");
        element?.reset();
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const editFunctionality = (e) => {
    setEditEntryData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const selectValues = async (ele) => {
    console.log(ele,"ele")
    const recievingdate = timeConversion(ele?.receivingDate);
    const shipmentDate = timeConversion(ele?.shipmentDate);
   var certObj = {}
    certificate.map((cert)=>{
    if (ele['certificateofanalysis'][cert?._id]){
      certObj[cert?._id] = ele['certificateofanalysis'][cert?._id]['file']
     }
    })
    getDateThreshold(ele?.loadingcodeAtManufacturer)
  
    await setEditEntryData({
      id: ele?.id,
      receivingDate: recievingdate,
      shipmentDate: shipmentDate,
      certificateofanalysis: certObj,
      originport: ele?.originport,
      origincountry: ele?.origincountry,
      destinationport: ele?.destinationport,
      destinationcountry: ele?.destinationcountry,
      quantity: ele?.quantity,
      shipmentTime:ele?.shipmentTime,
      receivingTime:ele?.receivingTime
    });
  };
  const EditEntrySubmit = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(editEntryData.originport)) {
      showToastMessage("Port Origin Cannot Only contain numbers", "red", 2);
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(editEntryData.origincountry)) {
      showToastMessage("Country Origin Cannot Only contain numbers", "red", 2);
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(editEntryData.destinationport)) {
      showToastMessage(
        "Destination Port Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (/^\d+$/.test(editEntryData.destinationcountry)) {
      showToastMessage(
        "Destination Country Cannot Only contain numbers",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    }
    else if (editEntryData?.originport?.toUpperCase() == editEntryData?.destinationport.toUpperCase()) {
      showToastMessage(
        "Origin Port and Destination Port cannot be same",
        "red",
        2
      );
      setIsFetching(false);
      return 0;
    } else if (editEntryData.receivingDate > editEntryData.shipmentDate) {
      showToastMessage(" Date of shipment cannot be before the date of receiving ");
      setIsFetching(false);
      return 0;
    }
    setIsFetching(true);
    setIsLoading(true);
    apiCall("post", updateItemExporter, editEntryData, true)
      .then((res) => {
        showToastMessage("Loading at packing editted Successfully", "green", 1);
        setrendControl(!rendControl);
        e.target.reset();
        setIsLoading(false);
        document.getElementById("closeModalButtonEdit").click();
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  const deleteFunctionality = (e) => {
    setIsLoading(true);
    setIsFetching(true);
    apiCall("post", deleteItemExporter, IdToDelete, true)
      .then((res) => {
        showToastMessage("Loaded at packing removed Successfulyy", "green", 1);
        setrendControl(!rendControl);
        setIsLoading(false);
        document.getElementById("closeModalButtonDelete").click();
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };
  useEffect(() => {
    getAllLoadingData();
  }, [currentPage, rendControl]);

  useEffect(() => {
    AllLoadingAtManufacturerCode();
  }, []);

  const searchTable = (e) => {
    var input, filter, table, tr, td, i, txtValue;
    input = e.target.value;
    filter = input.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  const searchParams = () => {
    setCurrentPage(1);
    setrendControl(!rendControl);
  };

  const checkboxFunctionality = (e) => {
    if (e.target.checked) {
      addNewEntryData["certificateofanalysis"][e.target.id] = "Yes";
    } else {
      addNewEntryData["certificateofanalysis"][e.target.id] = "No";
    }
  };

  const fileUploader = (e,isEdit) => {
    setIsLoading(true);
    setIsFetching(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    apiCall("post", uploadAFile, formData, true)
      .then((res) => {
        setIsLoading(false);
        isEdit ? editEntryData['certificateofanalysis'][e.target.id] =  res.data.data.Location :  addNewEntryData['certificateofanalysis'][e.target.id] = res.data.data.Location
        showToastMessage("File Uploaded Successfulyy", "green", 1);
        setIsFetching(false);
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, "red", 2);
        console.log(err);
        setIsFetching(false);
      });
    
  };

useEffect(()=>{
  addNewEntryData?.loadingAtManufacturerID !=='' && getDateThreshold(addNewEntryData?.loadingAtManufacturerID)
},[addNewEntryData?.loadingAtManufacturerID])
  const getDateThreshold = (id) =>{
    apiCall('post', queryLoadingAtManufacturer,  {
      "id": id
  } , true).then((result) => {
      console.log("🚀 ~ file: loadingAtManufacturer.js:134 ~ apiCall ~ result:", result)
      const dataa =  new Date(result?.data?.data?.dateoffillingpacking)?.toISOString().split('T')[0]
    setThresholdDate(dataa)
    }).catch((err) => {
      console.log("🚀 ~ file: loadingAtManufacturer.js:137 ~ apiCall ~ err:", err)
    });
  }
  return (
    <>
      <div>
        <div id="d-flex flex-column">
          <div>
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <h1
                      className="h3 mb-0 text-gray-800 first-heading"
                      id="usermanagement-h1"
                    >
                      Exporter
                    </h1>
                  </div>
                  <div class="col-sm">
                    <div className="search d-flex butonn">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Shipment ID"
                        onChange={(e) => {
                          dataForApiCall.search = e.target.value;
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={searchParams}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-2" style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalCenter"
                    >
                      <i className="fa fa-plus mr-2"></i>Add
                    </button>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Add New
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonAddNew"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={addNewEntry}
                                  id="form1"
                                >
                                  
                                  <div className="form-group row">
                                  <div className="col-sm-12 mb-3">
                                      <label>Manufacturer ID</label>
                                      <select
                                        id="loadingAtManufacturerID"
                                        onChange={storeValues}
                                        className="form-select form-control-select"
                                      >
                                        <option disabled selected>
                                          ---Please select an option---
                                        </option>
                                        {manufacturerCode?.length !== 0 &&
                                          manufacturerCode?.map((ele) => {
                                            return (
                                              <option value={ele?._id}>
                                                {ele?.loadingatManufacturerCode}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Shipment Time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="shipmentTime"
                                        min={dateThreshold}
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving Time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="receivingTime"
                                        min={dateThreshold}
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="receivingDate"
                                        min={dateThreshold}
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                      />
                                    </div>
                                    {/* <div className="col-6 mb-3 ">
                                      <label htmlFor="birthday">
                                        Food Safety Plan <br />
                                      </label>
                                     <input type="file" id="birthday" onChange={fileUploader} />
                                   {
                                    addNewEntryData.foodsafetyplan == "" ? "" : <span onClick={()=>{window.location.replace(addNewEntryData.foodsafetyplan)}}>Show Certificate</span>
                                   }
                                    
                                    </div> */}

                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Shipment date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="shipmentDate"
                                        placeholder="Date of Shipment"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Quantity
                                      </label>
                                      <input
                                        type="number"
                                        min="1"
                                        className="form-control form-control-user"
                                        id="quantity"
                                        placeholder="3 kg .."
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>

                                    <div className="col-12 mb-3">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="ms-2"
                                      >
                                        Certificate of Analysis
                                      </label>
                                      {
                                      certificate.map((cert)=>{
                                        return(
                                        <label>{cert?.name}
                                        <input type="file" onChange={fileUploader} id={cert._id} required={cert?.required} />
                                        </label>
                                        )
                                      })
                                      }
                                      <div>
                                        
                                      </div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Port origin
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="originport"
                                        placeholder="Origin"
                                        onChange={(e) => {
                                          storeValues(e);
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Origin country
                                      </label>
                                      <input
                                        className="form-control form-control-user"
                                        type="text"
                                        id="origincountry"
                                        onChange={storeValues}
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination Port
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="destinationport"
                                        placeholder="Thailand"
                                        onChange={storeValues}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination country
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="destinationcountry"
                                        onChange={storeValues}
                                      />
                                    </div>

                                 
                                  </div>
                                  <button
                                    className="btn btn-primary float-end"
                                    type="submit"
                                    style={{ display: "flex" }}
                                  >
                                    Submit
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div
                    className="modal fade"
                    id="editModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenter">
                            Edit Listing
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModalButtonEdit"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="p-2">
                                <form
                                  className="user"
                                  onSubmit={EditEntrySubmit}
                                >
                                  <div className="form-group row">
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving Date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control form-control-user"
                                        id="receivingDate"
                                        min={dateThreshold}
                                        value={editEntryData?.receivingDate}
                                        placeholder="Receiving Date"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3 ">
                                      <label htmlFor="birthday">
                                        Shipment Date
                                      </label>
                                      <input
                                        type="date"
                                        value={editEntryData?.shipmentDate}
                                        className="form-control form-control-user"
                                        id="shipmentDate"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>

                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1 mb-5">
                                        Origin Country
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="origincountry"
                                        value={editEntryData?.origincountry}
                                        placeholder="Origin Port ..."
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Origin Port
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="originport"
                                        value={editEntryData?.originport}
                                        placeholder="port ..."
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Shipment Time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="shipmentTime"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Receiving Time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control form-control-user"
                                        id="receivingTime"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination Country
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-user"
                                        id="destinationcountry"
                                        value={
                                          editEntryData?.destinationcountry
                                        }
                                        placeholder="thailand ..."
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Destination Port
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={editEntryData?.destinationport}
                                        id="destinationport"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>


                                    <div className="col-12 mb-3">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="ms-2"
                                      >
                                        Certificate of Analysis
                                      </label>
                                      {
                                      certificate.map((cert)=>{
                                        return(
                                          <>
                                        <label>
                                          {cert?.name}
                                        <input type="file" onChange={(e)=>{fileUploader(e,true)}} id={cert._id} />
                                        </label>
                                        {editEntryData['certificateofanalysis'][cert?._id] ? <a href={editEntryData['certificateofanalysis'][cert?._id]['file']}> Show file </a> :  ""}
                                        </>
                                        )
                                      })
                                      }
                                    </div>

                                    <div className="col-sm-6 mb-2">
                                      <label htmlFor="exampleInputEmail1">
                                        Quantity
                                      </label>
                                      <input
                                        type="number"
                                        min="1"
                                        className="form-control form-control-user"
                                        id="quantity"
                                        value={editEntryData?.quantity}
                                        placeholder="Quantity"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      />
                                    </div>
                                    {/* <div className="col-sm-6 mb-2">
                                      <label htmlFor="exampleInputEmail1">
                                        Food Safety Plan
                                      </label>
                                      <select
                                        className="form-control form-control-user"
                                        id="foodsafetyplan"
                                        value={editEntryData?.foodsafetyplan}
                                        placeholder="foodsafetyplan"
                                        onChange={(e) => {
                                          editFunctionality(e);
                                        }}
                                      >
                                        <option
                                          selected={
                                            editEntryData?.foodsafetyplan ==
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                          value="Yes"
                                        >
                                          Yes
                                        </option>
                                        <option
                                          selected={
                                            editEntryData?.foodsafetyplan ==
                                            "No"
                                              ? true
                                              : false
                                          }
                                          value="No"
                                        >
                                          No
                                        </option>
                                      </select>
                                    </div> */}
                                  </div>
                                  <button
                                    className="btn btn-primary float-end   d-flex flex-wrap   d-flex flex-wrap"
                                    type="submit"
                                    style={{ display: "flex" }}
                                  >
                                    Update
                                    <i
                                      style={
                                        isLoading
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                      className="fa fa-spinner fa-spin m-1"
                                    ></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary"></h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive custom-table">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered fold-table"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0"
                          >
                            <thead>
                              <tr>
                                <th rowSpan="2">Date of Receiving </th>
                                <th rowSpan="2">
                                  Loading code from Manufacturer
                                </th>
                                <th rowSpan="2">Food Safety Plan Available</th>
                                <th rowSpan="2">Certificate of Analysis</th>
                                <th rowSpan="2">Date of Shipment</th>

                                <th rowSpan="2">Quantity</th>
                                <th rowSpan="2">Company</th>
                                <th rowSpan="2">Origin Port</th>
                                <th rowSpan="2">Origin Country</th>
                                <th rowSpan="2">Destination Port</th>
                                <th rowSpan="2">Destination Country</th>
                                <th rowSpan="2">Shipment ID</th>
                                <th rowSpan="2">Receiving Time</th>
                                <th rowSpan="2">Shipment Time</th>
                                <th rowSpan="2" class="nowrap">
                                  Edit Actions
                                </th>
                              </tr>
                          
                            </thead>
                            {isFetching ? (
                              <tr>
                                <td colSpan="12">
                                  {" "}
                                  <TableLoader />
                                </td>
                              </tr>
                            ) : Data?.data?.docs?.length ? (
                              <tbody>
                                {Data?.data?.docs.map((ele, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{ele?.receivingDate}</td>
                                      <td>{ele?.loadingcodeAtManufacturer}</td>
                                      <td>
                                        <a
                                          href={ele?.foodsafetyplan}
                                          target="blank"
                                        >
                                          View File
                                        </a>
                                      </td>

                                      <td>
                                        {certificate.map((cert)=>{
                                          return(
                                          ele['certificateofanalysis'][cert?.id] ? <a href={ele['certificateofanalysis'][cert?.id]['file']}>{cert?.name} | </a> : "-"
                                          )
                                        })}
                                   </td>
                                      <td>{ele?.shipmentDate}</td>
                                      <td>{ele?.quantity}</td>
                                      <td>{ele?.company}</td>
                                      <td>{ele?.originport}</td>
                                      <td>{ele?.origincountry}</td>
                                      <td>{ele?.destinationport}</td>
                                      <td>{ele?.destinationcountry}</td>
                                      <td>{ele?.shipmentID}</td>
                                      <td>{ele?.receivingTime}</td>
                                      <td>{ele?.shipmentTime}</td>
                                      <td className="nowrap">
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#editModalCenter"
                                        >
                                          <i
                                            className="fa fa-edit nowrap"
                                            id={ele._id}
                                            onClick={() => {
                                              selectValues(ele);
                                            }}
                                          />
                                        </a>
                                        <a
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                        >
                                          <i
                                            className="fa fa-trash-o m-1"
                                            id={ele.id}
                                            onClick={(e) => {
                                              setIdToDelete({
                                                id: e.target.id,
                                              });
                                            }}
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tr>
                                <td colSpan="12">No Record Found</td>
                              </tr>
                            )}
                          </table>
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={Math.ceil(
                              totalDataLenght / postsPerPage
                            )}
                            previousLabel={"<"}
                            nextLabel={">"}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-number"}
                            previousLinkClassName={"page-number"}
                            nextLinkClassName={"page-number"}
                            activeLinkClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete an Entry
                </h5>
                <button
                  className="close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure ?</div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButtonDelete"
                >
                  Cancel
                </button>
                <a
                  className="btn btn-primary"
                  style={{ display: "flex" }}
                  onClick={deleteFunctionality}
                >
                  Delete{" "}
                  <i
                    style={
                      isLoading ? { display: "block" } : { display: "none" }
                    }
                    className="fa fa-spinner fa-spin m-1"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Exporter;
