import "../css/sb-admin-2.min.css";
import headerImg from "../img/logoforFoodChain.png";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../apicalls/apiRoutes";
import { apiCall } from "../apicalls/apiCall";
import { useEffect, useState } from "react";
import Timer from "./component Main/timer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword(params) {

  const showToastMessage = (text,color,notify) => {
    if (notify == 1) {
      toast.success(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
    else{
      toast.warning(text, {
        position: toast.POSITION.TOP_RIGHT,
        style: {color:color}
    });
    }
   
  }

  const navigate = useNavigate();
  var queryParameters = new URLSearchParams(window.location.search)
var emailToDeal = queryParameters.get("email")
  const [rendControl, setRendControl] = useState(false);
  useEffect(()=>{
     queryParameters = new URLSearchParams(window.location.search)
     emailToDeal = queryParameters.get("email")
  },[])
  useEffect(() => {
    setTimeout(() => {
      setConfirmationText("");
    }, 3000);
  }, [rendControl]);

  const [dto, setDto] = useState({
    email: emailToDeal,
    code: null,
    newPassword: "",
  });

  const dtoAdder = (e) => {
    setDto((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };
  const [confirmationText, setConfirmationText] = useState("");
const handleRedirect = () => {
    setTimeout(() => {
        navigate('/')
    }, 3000);
}
  const submitHandler = (e) => {
    e.preventDefault();
    apiCall("post", resetPassword, dto)
      .then((result) => {
        if (result.status==200){
          showToastMessage("Password reset Successfully", 'green' ,1)
              handleRedirect()
        }
      })
      .catch((err) => {
        showToastMessage(err?.response?.data?.message, 'red', 2)
      });
  };
  return (
    <div className="login-background" style={{ height: "100vh" }}>
       <ToastContainer 
       position="bottom-center"
       autoClose={2000}
       hideProgressBar={true}
       />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="p-5">
                    <div className="text-center">
                      <img src={headerImg} style={{width:'60%', height:'60%'}} className="mb-3" />
                      <h1 className="h4 text-gray-900 mb-2">
                        Reset Your Password?
                      </h1>
                      <p className="mb-4">
                        Please use the OTP sent to {emailToDeal} and insert below to verify.
                      </p>
                    </div>
                    <form className="user" onSubmit={submitHandler}>
                    
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="6"
                          minLength="6"
                          className="form-control form-control-user"
                          id="code"
                          onChange={dtoAdder}
                          aria-describedby="code"
                          placeholder="Enter OTP Code"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                
                          id="newPassword"
                          onChange={dtoAdder}
                          aria-describedby="newPassword"
                          placeholder="Enter new Password"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                      >
                        Reset Password
                      </button>
                    </form>
                    <p className="mt-4" style={{ textAlign: "center" }}>
                      {confirmationText}
                    </p>
                    <Timer email={emailToDeal}/>
                    <div className="justify-content-center d-flex">

                      <div className="creat-account mr-3"></div>
                      
                      <div className="have-login">
                        <a
                          className="small"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                        
                          Already have an account? Login!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
