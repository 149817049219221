import { forgotPassword } from "../../../../apicalls/apiRoutes";
import { apiCall } from "../../../../apicalls/apiCall";
import { toast } from 'react-toastify';

export function sendOTP (email){
    const showToastMessage = (text,color,notify) => {
        if (notify == 1) {
          toast.success(text, {
            position: toast.POSITION.TOP_RIGHT,
            style: {color:color}
        });
        }
        else{
          toast.warning(text, {
            position: toast.POSITION.TOP_RIGHT,
            style: {color:color}
        });
        }
      }
    
    apiCall('post', forgotPassword ,{email:email}, true).then((res)=>{
      if(res.status == 200){
        showToastMessage("OTP sent successfully", 'green' , 1)
      }
      else{
        showToastMessage("Server Error. Please try again later" , 'red' ,2)
      }
    })
    .catch((err)=>{
      showToastMessage(err?.response?.data.message , 'red' , 2)
    })
      }